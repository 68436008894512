import { Link, NavLink, useLocation, useHistory } from 'react-router-dom'
import { mainContext } from '../../reducer'
import LogoText from '../../assets/image/index/logo.png'
import dot from '../../assets/icon/dots.png'
import china from '../../assets/image/index/zg.png'
// import state from '../../assets/image/.png'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import detectEthereumProvider from '@metamask/detect-provider'
import { formatAddress, formatAmount } from '../../utils/format'
import { DownOutlined } from '@ant-design/icons';
import { Select, Drawer } from 'antd';
import Web3 from 'web3'

// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function Header (props) {
  const intl = useIntl()
  const { Option } = Select;
  const { addr } = props
  const { dispatch, state } = useContext(mainContext)
  const {address, pollTimer} = state
  const HashRouter = useHistory()
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)
  let web3 = undefined
  let web3Provider = undefined
  const handleMenuItemClick = () => {
    setShowMenu(false)
  }
  // 弹框
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const initWeb3 = async () => {
    // 检查新版MetaMask
    if (window.ethereum) {
      web3Provider = window.ethereum;
      try {
        // 请求用户账号授权
        await window.ethereum.enable();
      } catch (error) {
        // 用户拒绝了访问
        console.error("User denied account access")
      }
    } else {
      console.log(111)
    }
  }
  const linkWallet = async () => {
      initWeb3()
      const provider = await detectEthereumProvider()
      web3 = new Web3(provider);
      web3.eth.getAccounts(function(err, res) {
        console.log('res', res)
        dispatch({
          type: 'CHANGE_ADDRESS',
          address: res[0]
        })
      })
      const netVersion = await provider.request({ method: 'net_version' })
      const accounts = await provider.request({ method: 'eth_accounts' })
      console.log('web3:',netVersion, accounts, web3)
      if (typeof web3 !== 'undefined') {
          web3 = new Web3(web3.currentProvider);
          if (web3.currentProvider.isMetaMask == true) {
              // "MetaMask可用"
              console.log('metamask可以用')
          } else {
              // "非MetaMask环境"
              console.log('非MetaMask环境')
              alert('登录')
              // web3.personal.sign(web3.fromUtf8("Hello from wanghui!"), web3.eth.coinbase, console.log);
          }
      } else {
          console.log('没有监测到metamask')
      }
      if (!window.web3) {//用来判断你是否安装了metamask
        window.alert('Please install MetaMask first.');//如果没有会去提示你先去安装
        return;
      }
  }
  useEffect(() => {
  })
  return(
    <header
      className={`header ${showMenu ? 'menu-show' : ''}`}
      style={location.pathname === '/' ? { borderBottom: 'transparent' } : {}}
    >
      <div className='center'>
        <div className='header__box'>
          <Link to='/' className='header__logo'>
            <img src={LogoText} />
            <span>XEC</span>
          </Link>
          <div className='header__menu-wrapper'>
            <div className='header__btn'>
                {
                  address 
                   ?
                   <button className='connect-btn'>
                  {/* 取出地址 */}
                  <span
                  >
                    {formatAddress(address)}
                  </span>
                  {/* <img src={dot} /> */}
                </button>
                :
                <button className='connect-btn'>
                  {/* 打开浏览器插件连接 */}
                  <span
                    onClick={linkWallet}
                  >
                    <FormattedMessage id='linkWallet' /> 
                  </span>
                  {/* <img src={dot} /> */}
                </button>
                }
              </div>
          </div>
          
          {/* <div
            style={{
              padding: '0 6px',
              textAlign: 'center',
              backgroundColor: '#4EC3C0',
              color: '#fff',
              marginLeft: '5px',
              cursor: 'pointer',
              height: '40px',
              lineHeight: '40px',
              display: !addr ? 'none' : 'block'
            }}
          onClick={ () => {
              HashRouter.push('/ardrop')
            }
            
          }>
              分享
          </div> */}
          <div
            style={{
              padding: '0 6px',
              textAlign: 'center',
              background: 'rgba(255, 255, 255, 0.2)',
              color: '#fff',
              marginLeft: '5px',
              cursor: 'pointer',
              display: addr ? 'none' : 'block',
              height: '40px',
              borderRadius: '5px',
              lineHeight: '40px',
              fontSize: '14px'
            }}
          onClick={ () => {
              HashRouter.push('/')
            }
          }>
               <FormattedMessage id='fhsy' />
          </div>
          {
            state.locale == 'en'
            ?
            <div style={{
              padding: '0 6px',
              height: '20px',
              width: '32px'
            }}>
              <img style={{width: '100%', height: '100%'}} src={dot} />
            </div>
            :
            <div style={{
              padding: '0 6px',
              height: '20px',
              width: '32px'
            }}>
              <img style={{width: '100%', height: '100%'}} src={china} />
          </div>
          }
          {/* <div style={{
              padding: '0 6px',
              height: '20px',
              width: '32px'
            }}>
              <img style={{width: '100%', height: '100%'}} src={dot} />
          </div> */}
          <div onClick={showDrawer} style={{
              padding: '0 1px',
              height: '20px',
              width: '10px'
            }}>
              <DownOutlined style={{color: '#ffffff'}} />
          </div>
          <Drawer
            title={intl.formatMessage({
               id: 'yyqhs',
            })}
            placement="right"
            getContainer={false}
            closable={true}
            onClose={onClose}
            visible={visible}
          >
            <div className="cn-en" onClick={()=>{
                let locale = state.locale
                dispatch({
                  type: 'CHANGE_LOCALE',
                  locale: locale = 'cn'
                })
                setVisible(false);
              }} style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{width: '30px', display: 'flex', justifyContent: 'center'}}><img src={china} style={{width: '20px', height: '20px'}} alt="" /></div>
              <p style={{marginLeft: '20px', width: '80px', textAlign: 'center'}}>中文简体</p>
            </div>
            <div onClick={()=>{
                let locale = state.locale
                dispatch({
                  type: 'CHANGE_LOCALE',
                  locale: locale = 'en'
                })
                setVisible(false);
              }} className="cn-en" style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{width: '30px',display: 'flex', justifyContent: 'center'}}><img src={dot} style={{width: '20px', height: '20px'}} alt="" /></div>
              <p style={{marginLeft: '20px', width: '80px', textAlign: 'center'}}>English</p>
            </div>
            {/* <p onClick={()=>{
              let locale = state.locale
              dispatch({
                type: 'CHANGE_LOCALE',
                locale: locale = 'cn'
              })
              setVisible(false);
              }} style={{textAlign: 'center'}}>中文简体</p>
            <p onClick={()=>{
              let locale = state.locale
              dispatch({
                type: 'CHANGE_LOCALE',
                locale: locale = 'en'
              })
              setVisible(false);
              }} style={{textAlign: 'center'}}>English</p> */}
          </Drawer>
        </div>
      </div>
    </header>
  )
}

export default Header