const zh_CN = {
  jrsz: '加入XEC兑奖池',
  qxszc: '取消',
  qrszjs: '确认',
  sqxx: '授权',
  yex: '余额',
  // sge: '最小输入',
  sge: '最少认购',
  ggg: 'USDT',
  // ggg: '个',
  kthddd: '空投活动',
  linkWallet: '链接钱包',
  cbs: '复制链接',
  zzzzz: '邀请领分红',
  aaa: '请先连接钱包!',
  kt: '空投',
  fhsy: '返回首页',
  lp: '这是一个智能合约地址，请不要在其中转移任何令牌',
  dy: '第一轮兑换',
  de: '第二轮兑换',
  ds: '第三轮兑换',
  jxz: '进行中',
  dhjd: '兑换进度',
  dyljzdjs: '第一轮截止倒计时',
  derjzdjs: '第二轮截止倒计时',
  dsljzdjs: '第三轮截止倒计时',
  zheclck: '在BSC上查看',
  dslydhs: '第三轮已兑换',
  ywc: '已完成',
  delydh: '第二轮已兑换',
  dylydh: '第一轮已兑换',
  ckjl: '筹款记录',
  ljtz: '累计投资',
  dblj: '代币累计',
  zccxq: '众筹池详情',
  xmjj: '项目简介',
  ljqbfhfx: '请先连接钱包, 才可以分享',
  lqcg: '已接受邀请',
  lqsb: '领取失败',
  jkcxxxx: '是由一群数字资产研究者和区块链极客创建而成的一个专注区块链资产交易的去中心化平台，将面向全球用户提供安全、透明、稳定、高效的区块链资产交易服务',
  xlpl: '安全、去中心化、稳定、流动性是 JKC 的核心价值，结合落地生态为每一位投资者提供高效的投资环境和服务，同时投资者不必担心个人隐私保护或数字资产的安全性，平台专业的金融单元将为投资者提供丰富风险对冲工具和投资选择',
  wdjl: '我的奖励',
  zjyq: '直接邀请',
  cvcg: '复制成功',
  zls: '空投总量为280万，只需支付少量手续费即可实时领取',
  yqgz: '邀请规则',
  yqjkc: '邀请得空投；建立邀请关系，邀请人将获得1.5枚JKC，受邀人得6.5枚JKC',
  xsrzs: 'JKC总量为8亿枚，本次JKC空投280万枚，为了感谢JKC团队对我们对技术支持，我们将100万枚JKC赠与JKC官方，4.5亿JKC进行线上认筹。',
  jskt: 'JKC将于2021-06-21 24:00结束空投',
  zczl: '众筹总量',
  yi: '众筹总量：4.5亿JKC ',
  kaissj: '众筹开始时间',
  sanlunj: '众筹分为三轮进',
  dyl1: '第一轮：06月12日16:00到06月27日24:00；共计15日；1JKC = 0.008usdt；总量2.5亿；',
  dyl2: '第二轮：06月28日0:00到7月7日24:00；共计10日；1JKC = 0.015usdt；总量1.5亿；',
  dyl3: '第三轮：07月8日0:00到07月17日24:00；共计10日；1JKC = 0.3usdt；总量5千万；空投280万JKC；',
  xylindex: '每一轮时间终止或者众筹目标达成后,即开始进行下一轮众筹',
  ljqb: '链接钱包后，直接输入USDT数量，支付成功后，即可得到对应数量的JKC',
  huobiw: '本次众筹为BSC链的众筹，钱包需链接火币主网，或使用支持BSC的钱包',
  dldh: '每人单次众筹的最大额度为20000 USDT，可进行多次兑换',
  sms: '什么是JKC',
  jkcfz: 'JKC为客户提供数字货币领域全面、安全、便捷和专业的资产管理与增值服务，促进数字货币投资领域的发展',
  jsdjs: '结束倒计时',
  yjss: '已结束',
  lps: '令牌',
  rhhd: '如何获得JKC',
  jkchydz: '合约地址',
  zcdzs: '众筹地址',
  hhda: 'JKC 总量为8亿枚，本次JKC空投280万枚；为了感谢JKC团队对我们的技术支持，我们将100万枚JKC赠与JKC官方，4.5亿JKC进行线上认筹。后期流动性挖矿3.472亿枚',
  jcd: '检测到你有空投未领取 请领取',
  qx: '取消',
  lq: '接受',
  kssj: '开始时间',
  yyqhs: '语言切换',
  xx1: '众筹池信息',
  xx2: '众筹开始',
  xx3: '最小限额',
  xx4: '无',
  xx5: '最大限额',
  xx6: '代币信息',
  xx7: '名称',
  xx8: '合约地址',
  xx9: '总量',
  xx10: '项目信息',
  xx11: 'JKC 是由一群数字资产研究者和区块链极客创建而成的一个专注区块链资产交易的去中心化平台，将面向全球用户提供安全、透明、稳定、高效的区块链资产交易服务。安全、去中心化、稳定、流动性是 JKC 的核心价值，结合落地生态为每一位投资者提供高效的投资环境和服务，同时投资者不必担心个人隐私保护或数字资产的安全性，平台专业的金融单元将为投资者提供丰富风险对冲工具和投资选择',
  p1: '关于',
  p2: '参加 IDO',
  p3: '在BSC上查看',
  p4: '关于',
  p5: '关于XEC：XEC全称Europe championship coin ，中文名为欧冠币，其中X代表项目能够链接数字资产和体育产业的全产业链，代表无限可能，简称为XEC，2021年成立于欧洲体育之都西班牙的巴塞罗那，XEC是一种应用于体育竞技、体育明星IP数字化、NFT、电子竞技等领域的数字化代币，致力于把体育明星IP NFT化，帮助体育团队和体育产业的知名组织孵化和创建粉丝代币和NFT资产，打造全产业链一体化的体育资产和NFT去中心化发行、交易和流通平台，打造体育、竞技、娱乐全产业链条的区块链生态系统。',
  p6: '枚',
  p7: '间接邀请',
  p8: '众筹',
  p9: '地址',
  p10: '我的二维码',
  p11: '众筹规则',
  p12: '众筹总量：1000万',
  p13: '众筹开始时间：2021-07-01',
  p14: '众筹分为三轮进行：',
  p15: '第一轮：总量为500万枚XEC；0.15 USDT=1 XEC',
  p16: '第二轮：总量为300万枚XEC；0.20 USDT=1 XEC',
  p17: '第三轮：总量为200万枚XEC；0.25 USDT=1 XEC',
  p18: '每一轮结束后，即开始进行下一轮众筹。',
  p19: '众筹方式',
  p20: '链接钱包后，直接输入USDT数量，支付成功后，即可得到对应数量的XEC。',
  p21: '本次众筹为BSC链的众筹，钱包需链接火币主网，或使用支持BSC的钱包。',
  p22: '每人单次众筹的最大额度为100000 USDT，可进行多次兑换。',
  p23: '什么是XEC',
  p24: 'XEC是一个多链的去中心化平台，当前基于BSC链进行发行；XEC代币的具体应用场景为XEC平台的去中心化治理、以及通过XEC可以购买合作的体育明星周边商品、赠品、合作赛事的门票、以及体育IP NFT资产等。',
  p25: '令牌',
  p26: '如何获得XEC',
  p27: '合约地址',
  p28: 'XEC发行总量为10亿枚，其中1000万枚，即1%进行IDO认购，9%用于添加初始流动性，60%用于社区挖矿，30%用于体育领域的慈善事业捐赠，无私募无预挖，打造真正去中心化、社区化的体育粉丝meme代币。',
  p29: '您是否接受别人的邀请',
  p30: '认筹操作需进行授权',
  p31: '邀请朋友一起，扫码认筹',
  p32: 'XEC的创始团队来自于几个欧洲大型足球俱乐部的核心粉丝团队的领导者，并且和西班牙巴塞罗那足球俱乐部、巴黎圣日耳曼足够俱乐部和尤文图斯足球俱乐部等几个欧洲顶尖的足够俱乐部建立了广泛以及多方位的合作关系，XEC是一个多链的去中心化平台。当前基于BSC链进行发行，后续会陆续上线ETH、Polygon（matic）、AVAX、Solana等去中心化智能合约平台，XEC的核心产品为XEC粉丝代币发行平台、 XEC Swap、XEC NFT等产品，打造全生态的去中心化体育资产平台。'

}
export default zh_CN
