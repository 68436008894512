import React, { useReducer } from 'react'
const CHANGE_LOCALE = 'CHANGE_LOCALE'
const CHANGE_ADDRESS = 'CHANGE_ADDRESS'
const CHANGE_CONTRACT = 'CHANGE_CONTRACT'
const CHANGE_ISARDROP = 'CHANGE_ISARDROP'
const CHANGE_ARDROP = 'CHANGE_ARDROP'
const mainContext = React.createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.locale || 'zh' }
    case CHANGE_ADDRESS:
        return { ...state, address: action.address || null }
    case CHANGE_CONTRACT: 
        return { ...state, poolContract: action.poolContract || null }
    case CHANGE_ISARDROP: 
        return { ...state, isArdrop: action.isArdrop || true }
    case CHANGE_ARDROP: 
        return { ...state, Ardrop: action.Ardrop || {
          invitedNumOf: 0,
          upInvitedNumOf: 0,
          releasedTo: 0
        } }
    default:
      return state
  }
}

const ContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    locale: 'zh',
    address: null,
    poolContract: null,
    isArdrop: true,
    Ardrop: {
      invitedNumOf: 0,
      upInvitedNumOf: 0,
      releasedTo: 0
    }
  })
  return (
    <mainContext.Provider value={{ state, dispatch }}>
      {props.children}
    </mainContext.Provider>
  )
}

export { reducer, mainContext, ContextProvider }
