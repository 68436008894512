
import React from 'react'
import { Spin, Space } from 'antd';

function MessagelDialog(props) {
  const { showMessage, changeMessageDialog, statusObj } = props
  const close = () => {
    changeMessageDialog()
  }
  return (
  <div className="modal-show" style={ {display: showMessage ? 'block' : 'none'} }>
  <div className="wrapper" style={{zIndex: 10}}>
    <div className="modal">
      <div className="modal__box">
      <div className="form-app">
    <div className="form-app__inner transction-submitted">
      {
        statusObj.status === 0 ?  (
        <div className="transction-submitted__loading">
          {/* <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAFJ0lEQVRYR+2YfYhUVRTAz3nvzejsQu2yiZsFbR+goYatQrUYOSZmuzlv3ru3D5KSgkVSCEmk/hD6EAKjhKJIQkr7wIJ35zmzORhZA0WrxaKRJWiUrX2sQayLxUw7b987cZdZGd++Nzuzz4zA9+e95+N3zz33nnMfwgX4hBCdAPAcES0FABcADgDAFs7591HNY1QDtm0v8zxvPxHN8Nk6G4/Hu1Kp1HdRfEQCJCIUQpwAgBuCIBDxM8bYHf8ZYC6Xm18ul7+tBaCqaqthGCPThQyNYD6fnzE6Onqb53lzFUU53tLSciiZTP5d7ci27cWu6w7Uch6Lxdp1Xf/9ggJWkv49Ipo3YRgRT2iFTCbX9XNAchHFYvEsAMRDAH7lnF89XTipNymCtm23uK4rt+0qv2FEHGpubl6watWq4Yk5IcQzRPR0SA72MsZ2Bs1VDtdcTdP6dF3/LWwRkwCFEJuI6MVQBcQnGWMvTMwXCgXtzJkz2zzP2wgASmW8jIjPMsaeD7KTyWQ2eJ736niEEP/QNG1BWBoEAb5LRGtqAH7AGHvAPy+EuEZRlFtc1y0nEolDPT09p8NsWJb1BQB0VaXPw4yxdwJ3wT9oWdYbANBbA/AtxtijUfJKCLGDiNZVIkiapi3Wdf1IvYDdALCvBkCKc94XBTCfz19WLBa3AsA8VVV3GYaxp+4clIJCiLeJ6KEApT2c8wejwDWqG3gPygph2/Z6Iuolog4AGETEnaZpvoaIXqNOoshHKnVRHNerewmw3kjVPCTZbHaO4zjyck4iogYAnyuKstkwjB+iOoiqL9ulKwHgGyK6wmfsLwDovBBNZz2QuVzuWsdxViLiYGtr64FkMjk2fk9alrULANYGXpKI+xhj99TjIIrM3r17b3ddVza9TZXL2zJN8z5EHG84T1aukkk+EPFP0zQvl4JRAKbStSwrDwB3V8upqnqzYRhfS8DTRDQ7xIjDGJv5b999QoiDRHRrNQMiLmeMFSSg7PsCqwMifswYWzlVBKLOCyHWEdGOCTuy90wkEjd1d3ePjh8SIioAwFzfCn5RFGXZxTrJQggZJENWLQB4iTE2NJ6PldorT/IrRCSjpQJAQVXVjRcLrtYOXKokUfPz/xvBQqEwc3h4+E4iks/OE+3t7Z90dXWVokakUf3ACMoHueM4lv/ZqaoqT6fTRxt14peX/WYul1voed58ADhoGMZPNZuF6kn51i2VSkeI6MYApeNtbW2L/A/4RoCJSM1kMrt9D7NNnPPtgeXWP2jb9krXdT+q4bSHcy5L07S+TCaz1vM8Wf/P+zRNm59Op4/5xydtcSaTedzzvJdreA9dbT3EQog3ieiRANn1nPPXpwQUQqSIKBuaE4gmY8wOyatFiDiSSqVOhukLIbYS0ZZJIIg6Yyw3JeDAwEBscHDwMBEtCHByrKmpqVPWyOo5y7JWIOKeqp7yqKZpPel0+me/jWw2O9txHHnQZlXNfdnW1rZ0oges1gk8xfl8flapVPrUB3ksFost9/+isG17keu6/QCQOM8w4o/xeHzh6tWriwGQc8bGxjYDwPUA8FU8Ht8eJCf1Qi9qGclTp07dBQDXeZ53sqOjY/+SJUscvzPLsuQ/lg2BJxDxXsaYVU9uhqZUFGWpK4R4n4juD7KjKMpjpmmea6Om4ytyqbMs6wnZHoVEcDFj7PB0wCZ0IgP29/cnhoaGPiSi5T6QbZzzp6LA1czBRgz39fU1lctl+X9wBQCMIGLWNM3djdgIk/0H9GAeR1iG1hYAAAAASUVORK5CYII=" /> */}
            <Spin size="large" />
        </div>
        ) : ''
      }
      {
        statusObj.status === 1 ?  (<div className="emoji emoji--yay">
        <div className="emoji__face">
          <div className="emoji__eyebrows"></div>
          <div className="emoji__mouth"></div>
        </div>
      </div>
        ) : ''
      }
      {
        statusObj.status === -1 ?  (<div className="emoji  emoji--sad">
        <div className="emoji__face">
          <div className="emoji__eyebrows"></div>
          <div className="emoji__eyes"></div>
          <div className="emoji__mouth"></div>
        </div>
        </div>
        ) : ''
      }
      <h3 className="form-app__title h3">{statusObj.text}</h3>
      {
        statusObj.status !== 0 ? 
        <>
          <button type="button"
        className="transction-submitted__btn btn"
        onClick={close}
        >Close</button><button type="button" className="form-app__close-btn button"
            aria-label="Close">
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M14.5 10l7.39-7L24 5l-7.39 7L24 19l-2.11 2-7.39-7-7.39 7L5 19l7.39-7L5 5l2.11-2 7.39 7z"></path>
              </svg>
        </button>
        </>
        :
        ''
      }
        </div>
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default MessagelDialog
