const pools = [
  {
    name: 'FTC POOL',
    // address: '0xa3fe5fF5b8455d6df640cAA0D7C52E7560432F98', // pool地址池子
    address: '0x0Cf3A33302Ff6946406351C079D7a4F8372eD56d',
    start_at: '',
    is_top: true,
    is_coming: false,
    currency: {
      address: '0x7BCe9aEb33e39BBC5619B9317b196c479822415A', // 如果是0x0则是ht  usdt地址
      // address: '0x878257673d1d850A2123105984D1D48BAc8398b8',
      symbol: 'USDT',
      decimal: 18,
    },
    icon: '',
    type: 0,
    isPrivate: false,
    underlying: {
      address: '0xc22E956fbDDB93098BB2FC62304C3310E91D0E97', // 代币 jkc
      decimal: 18,
      symbol: 'JKC',
      name: 'SteakSwap Token ',
      total_supply: '1000000000',
      holders: '-',
      transfers: '-',
    },
    amount: '600',
    pool_info: {
      token_distribution: 'February 27th 2021, 9:30PM SGT',
      min_allocation: '0',
      max_allocation: '3 USDT',
      min_swap_level: '833 HT',
    },
    website: 'https://antimatter.finance',
    white_paper: '-',
    twitter: 'https://twitter.com/antimatterdefi',
    Telegram_Channel: 'https://t.me/antimatterchat',
    Github: 'https://github.com/antimatter-finance',
    yuque: '-',
    progress: 1,
    status: 0,
    ratio: '1USDT=200MATTER',
    time: '1617933600',
    purchasedCurrencyOf: 0,
    totalPurchasedAmount: '0',
    totalPurchasedUnderlying: '0',
    totalPurchasedCurrency: '0',
    is_flash: false,
    link_url: '',
    project_introduction: '',
  },
  {
    name: 'AIRDROP',
    address: '0x38D7e494807383d99eB0ce691557f66E892f9341', // 空投
    currency: {
      address: '0x4c6f6904C8A6118a4017b37233D82E1835bB0f93', // 如果是0x0则是ht 代币
      symbol: 'JKC',
      decimal: 18,
    },
  }
]

export default pools
