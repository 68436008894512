import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { message, Modal, Spin } from 'antd'
import { formatAmount, numToWei, splitFormat, toDecimal } from '../../utils/format'
import { mainContext } from '../../reducer.js'
import QRCode from 'qrcode.react'
import ardropJson from '../../web3/abi/ardrop.json'
import Header from '../../components/header/index'
import Starter from '../../web3/abi/Starter.json'
import jckindex from '../../assets/image/index/lll.png'
import china from '../../assets/image/index/zg.png'
import cv from '../../assets/image/index/cv.png'
import ido from '../../assets/image/index/zcdz.png'
import idos from '../../assets/image/idos.png'
import detectEthereumProvider from '@metamask/detect-provider'
import { FormattedMessage, useIntl } from 'react-intl'
import Web3 from 'web3'
import PoolDialog from './poolDialog'
import pools from './pools'
import MessageDialog from './message'
import Tab1 from './tab1'
import ProductANDClickDownload from '../../components/Qrcode'
import Tab2 from './tab2'
import { timers, getYMDHMS } from '../../utils/time.js'

const pool = pools[0]
const airpool = pools[0]

function GetHashString(name) { // 空投
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.hash
  r = r.substring(r.indexOf('?') + 1, r.length).match(reg)
  if (r != null) return unescape(r[2]); return null;
}
function Pool() {
  const intl = useIntl()
  const hashRoutr = useHistory()
  const txt = pool.address
  const { dispatch, state } = useContext(mainContext)
  const { address, poolContract, locale, Ardrop, isArdrop } = state // isardop 空投
  const [tabActive, setTabActive] = useState(1)
  const [init, setInit] = useState(0)
  const [initTime, setInitTime] = useState(0) // 空投
  const [cutTime, setCutTime] = useState(0) // 空投
  const [detime, setDetime] = useState(0) // 空投
  const [visible, setVisible] = useState(false); // 空投
  const airtxt = '1111111111' // 空投
  const [show, setShow] = useState(false) // 空投
  const [loading, setLoding] = useState(false) // 空投
  let ohterAddress = '' // 空投
  const [dialogShow, setDialogShow] = useState(false)
  const [messageDialogShow, setMessageDialogShow] = useState(false)
  const [statusObj, setStatusObj] = useState({
    status: 0,
    text: 'Transaction failed, please try again'
  })
  // let localAdd = window.location.protocol + '//' + window.location.host + '/#/ardrop'
  let localAdd = window.location.protocol + '//' + window.location.host + '/#/' // 空投
  let timer = null
  const changeText = ({ status, text }) => {
    setStatusObj({ status, text })
  }
  const changeModelShow = (bol) => { // 空投
    setShow(bol)
  }
  const shareHaibao = () => { // 空投
    if (address) {
      setShow(true)
    } else {
      // message.warning('请先连接钱包, 才可以分享')
      message.warning(
        intl.formatMessage({
           id: 'ljqbfhfx',
        })
      )
    }
  }
  const changeDialogShow = () => {
    if (address) {
      setDialogShow(!dialogShow)
    } else {
      // message.warning('请先连接钱包！')
      message.warning(
        intl.formatMessage({
           id: 'aaa',
        })
      )
    }
  }
  const changeMessageDialog = (bol) => {
    if (bol) {
      setDialogShow(!dialogShow)
      setMessageDialogShow(bol)
    } else {
      setDialogShow(false)
      setMessageDialogShow(false)
    }
  }
  const initFn = async () => {
    // const provider = await detectEthereumProvider()
    var web3 = new Web3(Web3.givenProvider || 'ws://some.local-or-remote.node:8546');
    // const web3 = new Web3(provider)
    let obj = {
    }
    // usdt函数: totalPurchasedCurrency
    // FTC函数: totalReleasedCurrency
    const pool_contract = new web3.eth.Contract(Starter, pool.address) // abi

    try {
      let completed = pool_contract.methods.completed().call()
      let totalPurchasedCurrency = pool_contract.methods.totalPurchasedCurrency().call()
      let totalReleasedCurrency = pool_contract.methods.totalReleasedCurrency().call()
      obj.totalPurchasedCurrency = await totalPurchasedCurrency
      obj.totalReleasedCurrency = await totalReleasedCurrency
      obj.completed = await completed
      console.log('--------------', obj);
      // debugger
      if (!obj.completed) {
        let status = pool_contract.methods.status().call()
        let minAmount = pool_contract.methods.minAmount().call()
        obj.status = await status
        try {
          obj.minAmount = await minAmount
        } catch (error) {
          console.log('--------------');
        }
        console.log('--------------', obj, obj.status.launchTime_, obj.status.endTime_);
        var launch = obj.status.launchTime_ // 开始时间
        var endt = obj.status.endTime_ // 倒计时
        var timestamp = Date.parse(new Date()) / 1000; // 当前时间戳
        if ( timestamp >= launch ) {
          obj.isTime = false
        } else {
          obj.isTime = true
        }
      } else {
        let status = pool_contract.methods.status().call()
        let minAmount = pool_contract.methods.minAmount().call()
        obj.status = await status
        dispatch({
          type: 'CHANGE_CONTRACT',
          poolContract: obj
        })
        clearInterval(timer)
      }
      dispatch({
        type: 'CHANGE_CONTRACT',
        poolContract: obj
      })
    } catch (e) {
      clearInterval(timer)
    }
    console.log('poolContract', obj, poolContract)
  }

  const initFns = async () => { // 空投

    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    let obj = {
    }
    // usdt函数: totalPurchasedCurrency
    // FTC函数: totalReleasedCurrency
    ohterAddress = GetHashString('address')
    // const airpool_contract = new web3.eth.Contract(ardropJson, pool.address)
    const airpool_contract = new web3.eth.Contract(ardropJson, airpool.address)
    // debugger
    // console.log('jjj', pool_contract)
    try {
      //         直接邀请人数invitedNumOf(当前钱包地址)
      //        间接邀请人数upInvitedNumOf(当前钱包地址)
      //          邀请奖励 releasedTo(当前钱包地址)
      let invitedBy = airpool_contract.methods.invitedBy(address).call()
      let invitedNumOf = airpool_contract.methods.invitedNumOf(address).call()
      let upInvitedNumOf = airpool_contract.methods.upInvitedNumOf(address).call()
      let releasedTo = airpool_contract.methods.releasedTo(address).call()
      // let time = airpool_contract.methods.time().call()
      obj.invitedBy = await invitedBy
      obj.invitedNumOf = await invitedNumOf //
      obj.upInvitedNumOf = await upInvitedNumOf
      obj.releasedTo = formatAmount(await releasedTo,  airpool.currency.decimal)
      // obj.time = await time


      dispatch({
        type: 'CHANGE_ARDROP',
        Ardrop: obj
      })
      closingDate()
    } catch (e) {
      // clearInterval(timer)
    }
    console.log('空投信息', obj)
    if (obj.invitedBy === '0x0000000000000000000000000000000000000000') { // 任何关系都没有
      // 未领取
      dispatch({
        type: 'CHANGE_ISARDROP',
        isArdrop: false
      })
      console.log('in---', ohterAddress)
      if (ohterAddress && address != ohterAddress ) {
        setVisible(true)
      }
    }
    setInit(1)
  }
  const handleOk = async () => { // 空投
    ohterAddress = GetHashString('address')
    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    const airpool_contract = new web3.eth.Contract(Starter,  airpool.address) // abi
    // console.log()
    // let invitedBy = airpool_contract.methods.invitedBy(address).call()
    // let isTrue = new web3.eth.Iban(ohterAddress)
    console.log('handleOk', ohterAddress, address)
    // if (!isTrue.isValid()) return message.warning('地址无效')
    // return
    airpool_contract.methods
      // .getAirDrop(ohterAddress)
      .inviter(ohterAddress) //新
      .send({
        from: address,
      })
      .on('transactionHash', (hash) => {
        console.log('空投等待....')
        setLoding(true)
      })
      .on('receipt', (_, receipt) => {
        console.log('BOT staking success')
        // message.error('领取成功！')
        message.success(
          intl.formatMessage({
             id: 'lqcg',
          })
        )
        setLoding(false)
        setVisible(false)
        initFns()
      })
      .on('error', (err, receipt) => {
        console.log('BOT staking error', err)
        // message.error('领取失败')
        message.error(
          intl.formatMessage({
             id: 'lqsb',
          })
        )
        setLoding(false)
        setVisible(false)
      })
  }
  function closingDate() {  // 空投
    let ddtime = getYMDHMS(Ardrop.time)  // 空投
    return ddtime // 空投
  } // 空投
  useEffect(async () => {
    if (init) return
    initFn()

    // if (address && isArdrop && !init && !detime) {  // 空投
    //   initFns()  // 空投
    // }  // 空投

    // timer = setInterval(() => {
    //   if (document.getElementById('poll') === null) {
    //     clearInterval(timer)
    //     return
    //   }
    //   initFn()
    // }, 1000)
    setInit(1)

  }) // 空投 [address]

  useEffect(async () => {
    console.log('hhhhhhhhhhhh')
    if (address) {
      console.log('uuuuuuuuuuuuu')
      initFns()
    }
  }, [address])

  useEffect(async () => { // 倒计时
    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    const airpool_contract = new web3.eth.Contract(ardropJson, pool.address)
    let time = await airpool_contract.methods.time().call()

    var timer
      timer = setInterval(() => {
        if (document.getElementById('alr') === null || !timers(time)) {
          clearInterval(timer)
          return
        }
        locale == 'en' ?
        setCutTime(timers(time, 123))
        :
        setCutTime(timers(time))
        // setCutTime(timers(time))
      }, 1000)
  }, [])
  const handCanel = () => { // 空投
    setVisible(false) // 空投
    console.log('取消') // 空投
  }
  return (
    <div className="pools-type-top">
      <div className="pool-top">
        <Header addr={true} />
        <div className="titleHeader">XEC<span>Public Pool</span></div>
        <div className="pools-type_content" id="poll">
        {/* <img className="imgindex" src={jckindex} /> */}
          <div className="pools_banner">
            <div className="pools_banner_img"></div>
            {/* <h3 className="pools_banner_text">FTC Public Pool</h3> */}
            <p className="pools_banner_dec">
              <span>{txt}</span>
              {/* cv成功 */}
              <CopyToClipboard text={txt}
                onCopy={() => message.success(
                  intl.formatMessage({
                     id: 'cvcg',
                  })
                )}>
                <img className="cv" src={cv} />
              </CopyToClipboard>

            </p>
            {/* <p c5lassName="pools_banner_withdraw_tip">这是兑换池合约地址，请不要向合约地址直接转币</p> */}
          </div>
        </div>
        <div className="tokens">*  <FormattedMessage id='lp' />。</div>
      </div>
      {/* 正在进行中 */}
      <div className="pools_card" style={{
        display:
          poolContract && !poolContract.completed
            ? 'block' : 'none',
            boxShadow: '2px 3px 10px 0px #ebebeb;'
      }}>
        <div className="pools_card_content">
          <div className="pools_card_content_title" >
            {
              poolContract && poolContract.status && poolContract.status.stage_ == 1 ?
                <span style={{ color: '#14144E' }}><FormattedMessage id='dy' /></span>
                :
                ''
            }
            {
              poolContract && poolContract.status && poolContract.status.stage_ == 2 ?
                <span style={{ color: '#14144E' }}><FormattedMessage id='de' /></span>
                :
                ''
            }
            {
              poolContract && poolContract.status && poolContract.status.stage_ == 3 ?
                <span style={{ color: '#14144E' }}><FormattedMessage id='ds' /></span>
                :
                ''
            }
            {/* 比例 */}
            <span style={{ fontWeight: 'normal', color: '#14144E' }}>
            {/* 1XEC = */}
            {poolContract && poolContract.status ? ' ' + formatAmount(poolContract.status.rate_, 18) : ''}
            {/* 枚 */}
            USDT/<FormattedMessage id='p6' /></span></div>
          {/* 第二排 */}
          <div className="pools_card_content_title pools_card_val">
            <div style={{width: '40%'}}>
              {
                poolContract && poolContract.status
                  ?
                  String(Number((formatAmount(poolContract.status.releasedVolume_, pool.underlying.decimal)))).replace(/^(.*\..{4}).*$/, "$1")

                  : ''

              }
              XEC
            </div>

             <div
              style={{
                color: '#14144E',
                opacity: '0.6',
                fontSize: '18px',
                width: '40%'
              }}
            >
              {
                poolContract && poolContract.status ?
                  String(Number(formatAmount(poolContract.status.rate_, 18) * formatAmount(poolContract.status.releasedVolume_, pool.underlying.decimal))).replace(/^(.*\..{4}).*$/, "$1")
                  + ' ' : ''
              }

                USDT</div>
          </div>
          <div style={{
            display: 'inline-block',
            marginTop: '25px',
            padding: '2px 10px',
            color: '#ffffff',
            background: '#19DC9F',
            fontSize: '12px',
            borderRadius: '6px'
          }}>
            <FormattedMessage id='jxz' />
              </div>
          <div className="pools_card_start"></div>
          <div className="pools_card_content_title"><span><FormattedMessage id='dhjd' /></span></div>
          <div className="pools_card_progress__bar">
            <p><a style={{
              width:
                poolContract && poolContract.status
                  ?
                  (formatAmount(poolContract.status.releasedVolume_, pool.underlying.decimal) * 100
                    / formatAmount(poolContract.status.suppliedVolume_, pool.underlying.decimal)) + '%'
                  : ''

            }}></a></p>
          </div>
          <div className="pools_card_content_title pools_card_schedule">
            <span style={{
              color: '#14144E',
              opacity: '0.6',
              fontWeight: 'normal'
            }}>
              {/* 百分数 */}
              {
                poolContract && poolContract.status
                  ?
                  (formatAmount(poolContract.status.releasedVolume_, pool.underlying.decimal) * 100
                    / formatAmount(poolContract.status.suppliedVolume_, pool.underlying.decimal)).toFixed(5)
                  : ''

              }
            %</span>
            <span
              style={{
                color: '#14144E',
                opacity: '0.6',
                fontWeight: 'normal'
              }}
            >
              {poolContract && poolContract.status
                ?
                formatAmount(poolContract.status.releasedVolume_, pool.underlying.decimal) +
                '/' + formatAmount(poolContract.status.suppliedVolume_, pool.underlying.decimal)
                : ''}
             XEC</span>
          </div>
          {/* 第一轮 */}
          {/* launchTime_ */}
          {/* <div className="count-i-down" style={{ marginTop: '10px',color: 'rgb(153, 153, 153)' }}>
            {
              poolContract && poolContract.status && poolContract.status.stage_ == 1 && poolContract.isTime ?
                <span>IDO<FormattedMessage id='kssj' />：
                  {poolContract && poolContract.status ? getYMDHMS(poolContract.status.launchTime_): ''}
                </span>
                :
                ''
            }
          </div> */}
          {/* 这个是倒计时 */}
            {/* {
              locale == 'en' ?
              <div className="count-i-down"> {
                poolContract && poolContract.status && poolContract.status.stage_ == 1 && !poolContract.isTime ?
                  <span><FormattedMessage id='dyljzdjs' />：{poolContract && poolContract.status ? timers(poolContract.status.endTime_, 123): ''}</span>
                  :
                  ''
              }</div>
              :
              <div className="count-i-down"> {
                poolContract && poolContract.status && poolContract.status.stage_ == 1 && !poolContract.isTime ?
                  <span><FormattedMessage id='dyljzdjs' />：{poolContract && poolContract.status ? timers(poolContract.status.endTime_): ''}</span>
                  :
                  ''
              }</div>
            } */}
            {/* {
              locale == 'en' ?
              <div className="count-i-down"> {
                poolContract && poolContract.status && poolContract.status.stage_ == 2 ?
                  <span><FormattedMessage id='derjzdjs' />：{poolContract && poolContract.status ? timers(poolContract.status.endTime_, 123): ''}</span>
                  :
                  ''
              }</div>
              :
              <div className="count-i-down"> {
                poolContract && poolContract.status && poolContract.status.stage_ == 2 ?
                  <span><FormattedMessage id='derjzdjs' />：{poolContract && poolContract.status ? timers(poolContract.status.endTime_): ''}</span>
                  :
                  ''
              }</div>
            } */}
            {/* {
              locale == 'en' ?
              <div className="count-i-down"> {
                poolContract && poolContract.status && poolContract.status.stage_ == 3 ?
                  <span><FormattedMessage id='dsljzdjs' />：{poolContract && poolContract.status ? timers(poolContract.status.endTime_, 123): ''}</span>
                  :
                  ''
              }</div>
              :
              <div className="count-i-down"> {
                poolContract && poolContract.status && poolContract.status.stage_ == 3 ?
                  <span><FormattedMessage id='dsljzdjs' />：{poolContract && poolContract.status ? timers(poolContract.status.endTime_): ''}</span>
                  :
                  ''
              }</div>
            } */}
          {/* <div className="ido"><img  onClick={changeDialogShow} src={ido} /></div> */}
          {/* {
            locale == 'en' ?
            <div className="ido">
              <img onClick={changeDialogShow} src= {idos} />
            </div>
            :
            <div className="ido">
              <img onClick={changeDialogShow} src={ido } />
            </div>
          } */}
          <div className="conversion">
            <div onClick={changeDialogShow} className="left">
              {/* 参加 IDO */}
              <FormattedMessage id='p2' />
            </div>
            <div style={{
              display:
                poolContract && !poolContract.completed
                  ? 'block' : 'none'
            }} className="right"><a style={{color: '#14144E'}} href={'https://testnet.bscscan.com/address/' + pool.address}
            target="_blank">
              {/* 在HECO上查看 */}
              <FormattedMessage id='p3' />
              </a></div>
          </div>
        </div>
      </div>
      {/* <div className="pools_detail_btn_box"
        style={{
          display:
            poolContract && !poolContract.completed
              ? 'block' : 'none'
        }}
      > */}
        {/* {
          address ?
            <a className="pools_detail_btn pools_detail_btn_active"
              onClick={changeDialogShow}
            >参加IDO</a>
            :
            <a className="pools_detail_btn pools_detail_btn_disable"
              onClick={changeDialogShow}
            >参加IDO</a>
        } */}
        {/* <a className="pools_detail_btn" href={'https://hecoinfo.com/address/' + pool.address}
          target="_blank"><FormattedMessage id='zheclck' /></a>
      </div> */}
      {/* 3 wacc */}
      {/* 关于 */}
      <div className="inregardto">
        <div className="inido"><FormattedMessage id='p4' /><span>XEC</span></div>
      </div>
      <div className="jies">
        {/* 关于XEC：XEC全称Europe championship coin ，中文名为欧冠币，其中X代表项目能够链接数字资产和体育产业的全产业链，代表无限可能，简称为XCH，2021年成立于欧洲体育之都西班牙的巴塞罗那，XEC是一种应用于体育竞技、体育明星IP数字化、NFT、电子竞技等领域的数字化代币，致力于把体育明星IP NFT化，帮助体育团队和体育产业的知名组织孵化和创建粉丝代币和NFT资产，打造全产业链一体化的体育资产和NFT去中心化发行、交易和流通平台，打造体育、竞技、娱乐全产业链条的区块链生态系统。 */}
        <FormattedMessage id='p5' />
      </div>
      <div className="pools_card" style={{
        display:
          (poolContract && poolContract.completed) || (poolContract && poolContract.completed && poolContract.status && poolContract.status.stage_ == 3)

            ? 'block' : 'none'
      }}
      >
        <div className="pools_card_content">
        {/* 1XEC = */}
          <div className="pools_card_content_title"><span style={{ color: '#19DC9F' }}><FormattedMessage id='dslydhs' /></span><span>0.25USDT/<FormattedMessage id='p6' /></span></div>
          <div className="pools_card_content_title pools_card_val">{
              poolContract && poolContract.status && poolContract.status.totalPurchasedCurrencyForThird ? formatAmount(poolContract.status.totalPurchasedCurrencyForThird, 18) : ''
            } XEC
          </div>
          <div style={{
            display: 'inline-block',
            marginTop: '25px',
            padding: '0 10px',
            color: '#fff',
            background: '#19DC9F',
            fontSize: '12px',
            borderRadius: '10px'
          }}>
            <FormattedMessage id='ywc' />
              </div>
          <div className="pools_card_start"></div>
          <div className="pools_card_content_title"><span><FormattedMessage id='dhjd' /></span></div>
          <div className="pools_card_progress__bar">
          <p><a style={{

              width:
                poolContract && poolContract.status
                  ?
                  (formatAmount(poolContract.status.totalPurchasedCurrencyForThird, pool.underlying.decimal) * 100
                    / formatAmount(poolContract.status.suppliedVolumeOfStageThird, pool.underlying.decimal)) + '%'
                  : ''
              }}></a></p>
          </div>
          <div className="pools_card_content_title pools_card_schedule">
            <span style={{
              color: '#1890ff'
            }}>
              { poolContract && poolContract.status
              ?
              toDecimal((formatAmount(poolContract.status.totalPurchasedCurrencyForThird, pool.underlying.decimal) * 100
              / formatAmount(poolContract.status.suppliedVolumeOfStageThird, pool.underlying.decimal))) + '%'
              : '' }
            </span>
            <span style={{
              color: '#999999'
            }}>{
              poolContract && poolContract.status && poolContract.status.totalPurchasedCurrencyForThird ? formatAmount(poolContract.status.totalPurchasedCurrencyForThird, 18) : ''
            }/{
              poolContract && poolContract.status && poolContract.status.suppliedVolumeOfStageThird ? formatAmount(poolContract.status.suppliedVolumeOfStageThird, 18) : ''
            } XEC</span>
          </div>
          {/* <div className="conversion">
            <div onClick={changeDialogShow} className="left">参加 IDO</div>
            <div style={{
              display:
                poolContract && !poolContract.completed
                  ? 'block' : 'none'
            }} className="right"><a style={{color: '#14144E'}} href={'https://hecoinfo.com/address/' + pool.address}
            target="_blank">在HECO上查看</a></div>
          </div> */}
        </div>
      </div>
      {/* 2 wanc */}
      <div className="pools_card" style={{
        display:
          (poolContract && poolContract.completed) || (poolContract && poolContract.status && poolContract.status.stage_ == 3)

            ? 'block' : 'none'
      }}>
        <div className="pools_card_content">
          {/* 第二轮已兑换 */}
          {/* 1XEC = */}
          <div className="pools_card_content_title"><span style={{ color: '#19DC9F' }}><FormattedMessage id='delydh' /></span><span>0.2USDT/<FormattedMessage id='p6' /></span></div>
          <div className="pools_card_content_title pools_card_val">{
              poolContract && poolContract.status && poolContract.status.totalPurchasedCurrencyForSecond ? formatAmount(poolContract.status.totalPurchasedCurrencyForSecond, 18) : ''
            }XEC
          </div>
          <div style={{
            display: 'inline-block',
            marginTop: '25px',
            padding: '0 10px',
            color: '#fff',
            background: '#19DC9F',
            fontSize: '12px',
            borderRadius: '10px'
          }}>
            {/* 已完成 */}
            <FormattedMessage id='ywc' />
              </div>
          <div className="pools_card_start"></div>
          <div className="pools_card_content_title">
            {/* 兑换进度 */}
            <span><FormattedMessage id='dhjd' /></span>
          </div>
          <div className="pools_card_progress__bar">
            <p><a style={{

              width:
                poolContract && poolContract.status
                  ?
                  (formatAmount(poolContract.status.totalPurchasedCurrencyForSecond, pool.underlying.decimal) * 100
                    / formatAmount(poolContract.status.suppliedVolumeOfStageSecond, pool.underlying.decimal)) + '%'
                  : ''
              }}></a></p>
          </div>
          <div className="pools_card_content_title pools_card_schedule">
            <span style={{
              color: '#1890ff'
            }}>{ poolContract && poolContract.status
              ?
              toDecimal((formatAmount(poolContract.status.totalPurchasedCurrencyForSecond, pool.underlying.decimal) * 100
              / formatAmount(poolContract.status.suppliedVolumeOfStageSecond, pool.underlying.decimal))) + '%'
              : '' }</span>
            <span style={{
              color: '#999999'
            }}>{
              poolContract && poolContract.status && poolContract.status.totalPurchasedCurrencyForSecond ? formatAmount(poolContract.status.totalPurchasedCurrencyForSecond, 18) : ''
            }/{
              poolContract && poolContract.status && poolContract.status.suppliedVolumeOfStageSecond ? formatAmount(poolContract.status.suppliedVolumeOfStageSecond, 18) : ''
            } XEC</span>
          </div>
          {/* <div className="conversion">
            <div onClick={changeDialogShow} className="left">参加 IDO</div>
            <div style={{
              display:
                poolContract && !poolContract.completed
                  ? 'block' : 'none'
            }} className="right"><a style={{color: '#14144E'}} href={'https://hecoinfo.com/address/' + pool.address}
            target="_blank">在HECO上查看</a></div>
          </div> */}
        </div>
      </div>
      {/* 1 wanc */}
      <div className="pools_card" style={{
        display:
          (poolContract && poolContract.completed) || (poolContract && poolContract.status && poolContract.status.stage_ != 1)

            ? 'block' : 'none'
      }}>
        <div className="pools_card_content">
          <div className="pools_card_content_title">
            <span style={{ color: '#333' }}>
              {/* 第一轮已兑换 */}
              <span><FormattedMessage id='dylydh' /></span>
              {/* 1XEC */}
            </span><span>0.15USDT/<FormattedMessage id='p6' /></span>
          </div>
          <div className="pools_card_content_title pools_card_val">{
              poolContract && poolContract.status && poolContract.status.totalPurchasedCurrencyForFirst ? formatAmount(poolContract.status.totalPurchasedCurrencyForFirst, 18) : ''
            } XEC</div>
          <div style={{
            display: 'inline-block',
            marginTop: '25px',
            padding: '0 10px',
            color: '#fff',
            background: '#19DC9F',
            fontSize: '12px',
            borderRadius: '10px'
          }}>
            {/* 已完成 */}
            <FormattedMessage id='ywc' />
              </div>
          <div className="pools_card_start"></div>
          <div className="pools_card_content_title"><span>
            {/* 兑换进度 */}
            <FormattedMessage id='dhjd' />
          </span></div>
          <div className="pools_card_progress__bar">
            <p><a style={{

              width:
                poolContract && poolContract.status
                  ?
                  (formatAmount(poolContract.status.totalPurchasedCurrencyForFirst, pool.underlying.decimal) * 100
                    / formatAmount(poolContract.status.suppliedVolumeOfStageFirst, pool.underlying.decimal)) + '%'
                  : ''
              }}></a></p>
          </div>
          <div className="pools_card_content_title pools_card_schedule">
            <span style={{
              color: '#1890ff'
            }}>{ poolContract && poolContract.status
              ?
              toDecimal((formatAmount(poolContract.status.totalPurchasedCurrencyForFirst, pool.underlying.decimal) * 100
              / formatAmount(poolContract.status.suppliedVolumeOfStageFirst, pool.underlying.decimal))) + '%'
              : '' }</span>
            <span style={{
              color: '#999999'
            }}>{
              poolContract && poolContract.status && poolContract.status.totalPurchasedCurrencyForFirst ? formatAmount(poolContract.status.totalPurchasedCurrencyForFirst, 18) : ''
            }/{
              poolContract && poolContract.status && poolContract.status.suppliedVolumeOfStageFirst ? formatAmount(poolContract.status.suppliedVolumeOfStageFirst, 18) : ''
            } XEC</span>
          </div>
          {/* <div className="conversion">
            <div onClick={changeDialogShow} className="left">参加 IDO</div>
            <div style={{
              display:
                poolContract && !poolContract.completed
                  ? 'block' : 'none'
            }} className="right"><a style={{color: '#14144E'}} href={'https://hecoinfo.com/address/' + pool.address}
            target="_blank">在HECO上查看</a></div>
          </div> */}
        </div>
      </div>
      {/* 筹款记录 */}
      {/* <div className="pools_detail_record">
        <div className="pools_detail_record_tab"><a className="active">筹款记录</a></div>
        <div className="pools_detail_record_box">
          <table className="pools_detail_record_title">
            <thead>
              <tr>
                <td>累计投资</td>
                <td>代币累计</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {poolContract && poolContract.totalPurchasedCurrency ?
                    formatAmount(poolContract.totalPurchasedCurrency, pool.currency.decimal)
                    : ''}
                </td>
                <td>{poolContract && poolContract.totalReleasedCurrency ?
                  formatAmount(poolContract.totalReleasedCurrency, pool.underlying.decimal)
                  : ''}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      {/* 我的奖励 */}
      <div className="pools_detail_index">
        <div className="pools-i">
          <div className="title">
            {/* 我的奖励 */}
            <FormattedMessage id='wdjl' />
          </div>
          <div className="number" style={{textAlign: 'center', marginTop: '0px'}}>{Number(Ardrop.releasedTo).toFixed(4)} XEC</div>
        </div>
        <div className="pools-i">
          <div className="title">
            {/* 直接邀请 */}
            <FormattedMessage id='zjyq' />
            {/* 直接邀请人数invitedNumOf(当前钱包地址)
      //        间接邀请人数upInvitedNumOf(当前钱包地址)
      //          邀请奖励 releasedTo(当前钱包地址) */}
          </div>
          <div className="number">{Ardrop.invitedNumOf}</div>
        </div>
        <div className="pools-i">
          <div className="title"><FormattedMessage id='p7' /></div>
          <div className="number">{Ardrop.upInvitedNumOf}</div>
        </div>
      </div>
      {/* 众筹地址 */}
      <div className="gathers">
        <div className="left">
          {/* <img src={cv} alt="" /> */}
          <CopyToClipboard text={txt}
            onCopy={() => message.success(
              intl.formatMessage({
                 id: 'cvcg',
              })
            )}>
            <img className="cv" src={cv} />
          </CopyToClipboard>
          <span>{txt}</span>
        </div>
        <div className="right">
          {/* <img src={ido} alt="" /> */}
          <div className="dzs"><FormattedMessage id='p8' /><span><FormattedMessage id='p9' /></span></div>
        </div>
      </div>
      {/* 二维码 */}
      <div className="ewxzsd">
        <QRCode locale={ locale } id='qrid' value={localAdd + '?address=' + address}  size={140} />
      </div>
      <div className="wdewm" onClick={shareHaibao}>
        {/* 我的二维码 */}
        <FormattedMessage id='p10' />
      </div>
      {/* 筹款记录 */}
      {/* <div className="raise-funds">
        <div className="recode" style={{marginTop: '20px'}}>
          <FormattedMessage id='ckjl' />
        </div>
        <div className="recode-list">
          <div className="list-1">
            <div className="span"><FormattedMessage id='ljtz' /> USDT</div>
            <div className="span"><FormattedMessage id='dblj' /></div>
          </div>
          <div className="list-2">
            <div className="list-top">
              <div className="l-1">
                {poolContract && poolContract.totalPurchasedCurrency ?
                  formatAmount(poolContract.totalPurchasedCurrency, pool.currency.decimal)
                    : ''}
              </div>
              <div className="l-2">USDT</div>
            </div>
            <div className="list-bottom">
              <div className="l-1">
                {poolContract && poolContract.totalReleasedCurrency ?
                  formatAmount(poolContract.totalReleasedCurrency, pool.underlying.decimal)
                  : ''}
              </div>
              <div className="l-2">JKC</div>
            </div>
          </div>
        </div>
      </div> */}
      {/* --- tab介绍 */}
      <div className="pools_detail">
        <div className="pools_detail_content">
          <div className="pools_detail_content_tab">
            <a onClick={
              () => { setTabActive(1) }
            } className={tabActive === 1 ? 'tabActive' : ''}>
              {/* 众筹池详情 */}
              <FormattedMessage id='zccxq' />
            </a>
            {/* <a onClick={
          () => { setTabActive(1) }
        } className={tabActive === 2 ? 'tabActive' : '' }>项目简介</a> */}
          </div>
          <Tab1 />
          {/* <Tab2 /> */}
        </div>
      </div>
      <div className="pools_detail">
        <div className="pools_detail_content">
          <div className="pools_detail_content_tab">
            <a onClick={
              () => { setTabActive(1) }
            } className={tabActive === 2 ? 'tabActive' : ''}>
              {/* 项目简介 */}
              <FormattedMessage id='xmjj' />
            </a>
          </div>
          <Tab2 />
        </div>
      </div>
      {/* 众筹规则 */}
      <div className="gzsaxc">
        <div className="vent">
          <div className="gas-1">
            <FormattedMessage id='p11' />
          </div>
          <div className="div-c">
            <div style={{marginTop: '20px'}}><FormattedMessage id='p12' /></div>
            <br/>
            <div><FormattedMessage id='p13' /></div>
            <br/>
            <div><FormattedMessage id='p14' /></div>
            <br/>
            <div><FormattedMessage id='p15' /></div>
            <br/>
            <div><FormattedMessage id='p16' /></div>
            <br/>
            <div><FormattedMessage id='p17' /></div>
            <br/>
            <div><FormattedMessage id='p18' /></div>
          </div>
        </div>
      </div>
      <div className="gzsaxc" style={{marginTop: '30px'}}>
        <div className="vent">
          <div className="gas-1">
          <div><FormattedMessage id='p19' /></div>
          </div>
          <div className="div-c">
            <div style={{marginTop: '20px'}}><div><FormattedMessage id='p20' /></div></div>
            <br/>
            <div><FormattedMessage id='p21' /></div>
            <br/>
            <div><FormattedMessage id='p22' /></div>
          </div>
        </div>
      </div>
      <div className="xec-index">
       <FormattedMessage id='p23' />
      </div>
      <div className="ineex-iii">
        <img src={jckindex} alt="" />
        <span>XEC</span>
      </div>
      <div className="ddd-lll">
        <FormattedMessage id='p24' />
      </div>
      <div className="xec-index">
        <FormattedMessage id='p25' />
      </div>
      <div style={{marginTop: '30px'}} className="ddd-lll">
        <div><FormattedMessage id='p26' /></div>
        <div style={{marginTop: '20px'}}>XEC <FormattedMessage id='p27' />：</div>
        <div style={{ wordWrap: 'break-word'}}>https://testnet.bscscan.com/token/0xc22E956fbDDB93098BB2FC62304C3310E91D0E97</div>
        <br/>
        {/* <div style={{marginTop: '20px'}}>众筹地址：</div> */}
          {/* http://hskdfksfhofhakfnahfaifafka */}
        <div style={{marginTop: '20px', fontSize: '12px', opacity: '0.6'}}><FormattedMessage id='p28' /></div>
      </div>
      {/* 弹出框 */}
      <PoolDialog poolContrac={poolContract} changeText={changeText} show={dialogShow} changeDialogShow={changeDialogShow} changeMessageDialog={changeMessageDialog} />
      <MessageDialog statusObj={statusObj} showMessage={messageDialogShow} changeMessageDialog={changeMessageDialog} ></MessageDialog>
      {/* 链接 */}
      <ProductANDClickDownload changeModelShow={changeModelShow} show={show} link={localAdd + '?address=' + address} />
      {/* title="领取空投" */}
      <Modal
        title={intl.formatMessage({
           id: 'zzzzz',
        })}
        centered
        closable={false}
        visible={visible}
        footer={
          [] // 设置footer为空，去掉 取消 确定默认按钮
        }
      >
        <p>
          {/* 检测到你有空投未领取 请领取 */}
          {/* <FormattedMessage id='jcd' /> */}
          <FormattedMessage id='p29' />
        </p>
        <Spin spinning={loading} delay={500}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-around'
          }}>

            <a className="pools_detail_btn pools_detail_btn"
              style={{ textAlign: 'center' }}
              onClick={handCanel}
            ><FormattedMessage id='qx' /></a>
            <a className="pools_detail_btn pools_detail_btn_active"
              style={{
                textAlign: 'center', color: '#fff',
                background: '#1890ff'
              }}
              onClick={handleOk}
            ><FormattedMessage id='lq' /></a>
          </div>
        </Spin>
      </Modal>
    </div>
  )
}

export default Pool
