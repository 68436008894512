import QRCode from 'qrcode.react'
import { useState, useEffect, useContext } from 'react'
import { message } from 'antd'
import { mainContext } from '../reducer.js'
import { Modal } from 'antd'
import cv from '../assets/image/cv.png'
import { CloseOutlined } from '@ant-design/icons'
import Habibao from '../assets/image/index/g1.png'
import bbj from '../assets/image/index/g2.png'
import hab from '../assets/image/index/logo.png'
import { FormattedMessage, useIntl } from 'react-intl'
import { CopyToClipboard } from 'react-copy-to-clipboard'

function ProductANDClickDownload (props) {
  const { link, show, changeModelShow } = props
  const { dispatch, state } = useContext(mainContext)
  const { address, isArdrop, Ardrop, locale } = state
  const intl = useIntl()
  const ClickDownLoad = () => {
    const downloadCardId = `.qrcode`;
    let canvas = document.querySelector(downloadCardId);
    canvas = canvas.toDataURL("image/png");
    var oA = document.createElement("a");
    oA.download = `二维码`;// 设置下载的文件名，默认是'下载'
    oA.href = canvas;
    document.body.appendChild(oA);
    oA.click();
    oA.remove(); // 下载之后把创建的元素删除
  }
  useEffect(() => {
    let mask = document.querySelector('.ant-modal-centered')
    let antd_body = document.querySelector('.qrcode .ant-modal-body')
    let content = document.querySelector('.qrcode .ant-modal-content')
    console.log('antd_body',mask, antd_body)
    if (mask && show) {
      antd_body.style.padding = '0'
      content.style.backgroundColor = '#000000'
      document.querySelector('.qrcode .ant-modal-footer').style.border = 'none'
    }
  })
  return (
    // <a download id='aId' title="点我下载！">
    //   
    // </a>
    <Modal title="" visible={show} 
    closable={false}
    className="qrcode"
    width={370}
    centered
    footer={
      [] // 设置footer为空，去掉 取消 确定默认按钮
      }
    >
      <div style={{
        position: 'relative'
      }}>
        {/* src={locale == 'en' ? hab : Habibao } */}
        <img src={ locale == 'en' ? bbj : Habibao } alt=""
          style={{
            width: '100%',
            height: '676px'
          }}
        />
        {/* logo */}
        <div style={{
          position: 'absolute',
          top: '20px',
          left: '18px',
          textAlign: 'center',
          lineHeight: '30px'
        }}>
          <img style={{width: '40px', height: '40px'}} src={hab} alt="" />
        </div>
        {/* 内容 */}
        {/* <div style={{
          position: 'absolute',
          top: '80px',
          left: '4%',
          width: '92%',
          height: '360px',
          background: '#FFFFFF',
          border: '5px solid #76E8B1',
          borderRadius: '12px'
        }}>
          <div style={{width: '94%', margin: '0 auto', marginTop: '10px'}}>
            <div style={{color: '#14144E', fontSize: '18px', textDecoration: 'underline'}}>邀请规则:</div>
            <div style={{opacity: '0.8', marginTop: '20px'}}>
            若双方建立了直接邀请关系，邀请人将得到5%的兑换代币奖励，受邀人得到2%的兑换代币奖励；如果是二级邀请，则间接邀请人将得到3%的兑换代币奖励；如果不接受邀请，只能获得自己投资额对应的兑换代币量。
              <br/>
              <div style={{opacity: '0.8', marginTop: '20px'}}>举例：假设A邀请了B，B邀请了C，C于第一轮IDO认购15000U，获得100000枚币，那么B得到C认购代币的5%，即5000枚，A得到C认购代币的3%，即3000枚，C本人得到2%的代币加成，即2000枚，也就是C最终得到的代币数量为102000枚。如果C没有接受任何人的邀请，则C只能得到100000枚代币，没有任何加成。</div>
            </div>
          </div>
        </div> */}
        <div style={{
          position: 'absolute',
          top: '6px',
          right: '18px',
          backgroundColor: '#fff',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          textAlign: 'center',
          lineHeight: '30px'
        }} onClick={
          () => {
            changeModelShow(false)
          }
        } >
          <CloseOutlined />
        </div>
        <div style={{fontSize: '12px',color: '#14144E',  position: 'absolute', top: '500px', left: '20px'}}>
          {/* 邀请朋友一起，扫码认筹 */}
          <FormattedMessage id='p31' />
        </div>
        <div style={{fontSize: '12px',color: '#14144E',  position: 'absolute', top: '520px', left: '20px'}}>https://xec.world</div>
        <div style={{
          position: 'absolute',
          bottom: '40px',
          right: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          border: '2px solid #76E8B1',
          width: '100px',
          height: '100px',
          background: '#ffffff',
          borderRadius: '12px'
        }} >
        <QRCode id='qrid' value={link}  size={80} /> 
        </div>
        {/* message.success('复制成功~') */}
        <CopyToClipboard text={link}
	          onCopy={() => message.success(
              intl.formatMessage({
                 id: 'cvcg',
              })
            )}>
	          <div style={{
              textAlign: 'center',
              fontSize: '12px',
              width: '90px',
              height: '25px',
              background: '#14144E',
              borderRadius: '19px',
              color: '#ffffff',
              lineHeight: '25px',
              position: 'absolute',
              left: '20px',
              bottom: '100px',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}><a style={{color: '#ffffff',}}><FormattedMessage id='cbs' /></a><img style={{width: '16px', height: '16px'}} src={cv} /></div>
	      </CopyToClipboard>
      </div>
      
    </Modal>
  )
}

export default ProductANDClickDownload