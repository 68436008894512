import React from 'react'
import pools from './pools'
import { FormattedMessage } from 'react-intl'

const pool = pools[0]
function tab1 () {
  return (
    <div className="pools_detail_table_box">
      <table className="pools_detail_table pools-detail_table__left">
        <thead>
          <tr>
            <th><FormattedMessage id='xx1' /></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p><span><FormattedMessage id='xx2' /></span><span>2021-07-01 00:00:00</span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span><FormattedMessage id='xx3' /></span><span><FormattedMessage id='xx4' /></span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span><FormattedMessage id='xx5' /></span><span>100000 USDT</span></p>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="pools_detail_table pools-detail_table__right">
        <thead>
          <tr>
            <th><FormattedMessage id='xx6' /> </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p><span><FormattedMessage id='xx7' /></span><span>XEC </span></p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span><FormattedMessage id='xx8' /></span>
                {/* <span>{pool.underlying.address}</span> */}
                <span>0xc22E956fbDDB93098BB2FC62304C3310E91D0E97</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p><span><FormattedMessage id='xx9' /></span><span>1000000000</span></p>
            </td>
          </tr>
          {/* <tr style={{
            borderLeft: '1px solid rgb(235, 233, 227)'
          }}>
            <td>
              <p><span>经济模型</span>
              <span style ={{
                padding: '0 0 0 10px',
              }}>10%进行IDO，20%用于运营、研发、社区发展；70%用于流动性挖矿、交易挖矿</span></p>
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  )
}

export default tab1
