import React, { useEffect, useContext, useState } from 'react'
import QRCode from 'qrcode.react'
import { useHistory } from 'react-router-dom';
import { mainContext } from '../../reducer.js'
import { formatAmount, numToWei, splitFormat } from '../../utils/format'
import { getYMDHMS } from '../../utils/time'
import LogoText from '../../assets/image/jcklogo.png'
import Kj from '../../assets/image/kj.png'
import jkc from '../../assets/image/index1.png'
import zcdz from '../../assets/image/index2.png'
import zcdzs from '../../assets/image/index22.png'
import dzewm from '../../assets/image/index3.png'
import dzewms from '../../assets/image/index33.png'
import ktsm from '../../assets/image/index4.png'
import ktsms from '../../assets/image/index44.png'
import index5 from '../../assets/image/index5.png'
import index55 from '../../assets/image/index55.png'
import index6 from '../../assets/image/index6.png'
import index66 from '../../assets/image/index66.png'
import cv from '../../assets/image/cv.png'
import Foot from '../../components/Foot'
import { FormattedMessage, useIntl } from 'react-intl'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Header from '../../components/header/index'
import ProductANDClickDownload from '../../components/Qrcode'
import ardropJson from '../../web3/abi/ardrop.json'
import detectEthereumProvider from '@metamask/detect-provider'
import Web3 from 'web3'
import { Modal, Spin, message } from 'antd'
import pools from '../pool/pools'
import banner from '../../assets/image/banner.png'
import { timers } from '../../utils/time.js'
// import Starter from '../../web3/abi/Starter.json'

const pool = pools[1]
const isPool = pools[0]
/**
 * 
 * @param {*} props 
 * @returns 
 * 
 * MetaMask 会向网页注入web3实例挂载window
 * 先判断有无 metamask 
 * 有 再看有无连接网站 没有进行授权
 */
function GetHashString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.hash
  r = r.substring(r.indexOf('?') + 1, r.length).match(reg)
  if (r != null) return unescape(r[2]); return null;
}
function Ardrop(props) {
  const hashRoutr = useHistory()
  const intl = useIntl()
  let timer = null
  const { dispatch, state } = useContext(mainContext)
  const { address, isArdrop, Ardrop, locale } = state
  const [init, setInit] = useState(0) // init 声明的变量 setInit改变的变量  0 初始值  useState({})  useState([]) 可以是对象，可以是数组
  const [initTime, setInitTime] = useState(0)
  const [cutTime, setCutTime] = useState(0)
  const [detime, setDetime] = useState(0)
  const [visible, setVisible] = useState(false);
  const txt = '1111111111'
  const [show, setShow] = useState(false)
  const [loading, setLoding] = useState(false)
  let ohterAddress = ''
  let localAdd = window.location.protocol + '//' + window.location.host + '/#/ardrop'
  const changeModelShow = (bol) => {
    setShow(bol)
  }
  const shareHaibao = () => {
    if (address) {
      setShow(true)
    } else {
      // message.warning('请先连接钱包, 才可以分享')
      message.warning(
        intl.formatMessage({
           id: 'ljqbfhfx',
        })
      )
    }
  }
  const handleOk = async () => {
    ohterAddress = GetHashString('address')
    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    const pool_contract = new web3.eth.Contract(ardropJson, pool.address)
    let invitedBy = pool_contract.methods.invitedBy(address).call()
    let isTrue = new web3.eth.Iban(ohterAddress)
    // debugger
    console.log('handleOk', ohterAddress, isTrue, address)
    // if (!isTrue.isValid()) return message.warning('地址无效')
    // return
    pool_contract.methods
      .getAirDrop(ohterAddress)
      .send({
        from: address,
      })
      .on('transactionHash', (hash) => {
        console.log('空投等待....')
        setLoding(true)
      })
      .on('receipt', (_, receipt) => {
        console.log('BOT staking success')
        // message.error('领取成功！')
        message.error(
          intl.formatMessage({
             id: 'lqcg',
          })
        )
        setLoding(false)
        setVisible(false)
        initFn()
      })
      .on('error', (err, receipt) => {
        console.log('BOT staking error', err)
        // message.error('领取失败')
        message.error(
          intl.formatMessage({
             id: 'lqsb',
          })
        )
        setLoding(false)
        setVisible(false)
      })
  }
  const initFn = async () => {

    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    let obj = {
    }
    // usdt函数: totalPurchasedCurrency 
    // FTC函数: totalReleasedCurrency
    ohterAddress = GetHashString('address')
    const pool_contract = new web3.eth.Contract(ardropJson, pool.address)
    // debugger
    // console.log('jjj', pool_contract)
    try {
      //         直接邀请人数invitedNumOf(当前钱包地址)
      //        间接邀请人数upInvitedNumOf(当前钱包地址)
      //          邀请奖励 releasedTo(当前钱包地址)
      let invitedBy = pool_contract.methods.invitedBy(address).call()
      let invitedNumOf = pool_contract.methods.invitedNumOf(address).call()
      let upInvitedNumOf = pool_contract.methods.upInvitedNumOf(address).call()
      let releasedTo = pool_contract.methods.releasedTo(address).call()
      let time = pool_contract.methods.time().call()
      obj.invitedBy = await invitedBy
      obj.invitedNumOf = await invitedNumOf // 
      obj.upInvitedNumOf = await upInvitedNumOf
      obj.releasedTo = formatAmount(await releasedTo, pool.currency.decimal)
      obj.time = await time


      dispatch({
        type: 'CHANGE_ARDROP',
        Ardrop: obj
      })
      closingDate()
    } catch (e) {
      // clearInterval(timer)
    }
    console.log('空投信息', obj)
    if (obj.invitedBy === '0x0000000000000000000000000000000000000000') {
      // 未领取
      dispatch({
        type: 'CHANGE_ISARDROP',
        isArdrop: false
      })
      console.log('in---', ohterAddress)
      if (ohterAddress && address != ohterAddress ) {
        setVisible(true)
      }
    }
    setInit(1)
  }
  function closingDate() {
    let ddtime = getYMDHMS(Ardrop.time)
    return ddtime
  }
  useEffect(async () => {
    console.log('zzz')
    if (address && isArdrop && !init && !detime) {
      initFn()
    }
  }, [address])

  useEffect(async () => { // 倒计时
    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    const pool_contract = new web3.eth.Contract(ardropJson, pool.address)
    let time = await pool_contract.methods.time().call()
    
    var timer
      timer = setInterval(() => {
        if (document.getElementById('alr') === null || !timers(time)) {
          clearInterval(timer)
          return
        }
        locale == 'en' ?
        setCutTime(timers(time, 123))
        :
        setCutTime(timers(time))
        // setCutTime(timers(time))
      }, 1000)
  }, [])

  const handCanel = () => {
    setVisible(false)
    console.log('取消')
  }

  return (
    <div id="alr" className="pools-type-top" style={{
      background: "url(" + banner + ")",
      backgroundSize: '100% 100%'
    }}>
      <Header addr={false} style={{
        background: '#000'
      }} />
      <div className="pools-type_content" >
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <div className="banners-index"><img style={{
            width: '100%',
            height: '100%',
          }} src={Kj} /></div>
        </div>
        <div className="banner" id="airdrop" >
          {/* <div className="banner_img"></div> */}
          {/* <h3 className="banner_text" style={{
        color: '#fff'
      }}>FTC</h3> */}
          <img src={jkc} />
          {/* <div class="img-jck">55</div> */}
          <p className="banner_dec" style={{
            color: '#FFE1EAFE'
          }}>JKC 
            {/* 是由一群数字资产研究者和区块链极客创建而成的一个专注区块链
            资产交易的去中心化平台，将面向全球用户提供安全、透明、稳定、高效的区块
            链资产交易服务。 */}
            <FormattedMessage id='jkcxxxx' />
            <div style={{
              height: '20px',
            }}></div>
              {/* 安全、去中心化、稳定、流动性是 JKC 的核心价值，结合
              落地生态为每一位投资者提供高效的投资环境和服务，同时投资者不必担心个人
              隐私保护或数字资产的安全性，平台专业的金融单元将为投资者提供丰富风险对
              冲工具和投资选择 */}
              <FormattedMessage id='xlpl' />
            </p>
        </div>
        <div className="xian-1"></div>
        <div className="xian-2"></div>
      </div>
      {/* 邀请奖励 */}
      {/* <div className="pools_detail_record" style={{
        height: '80px',
        minHeight: '80px'
      }}>
        <div className="pools_detail_record_box">
          <table className="pools_detail_record_title">
            <thead>
              <tr style={{
                color: '#2e2e2f'
              }}>
                <td  style={{
                color: '#2e2e2f'
              }}>直接邀请人数</td>
                <td  style={{
                color: '#2e2e2f'
              }}>间接邀请人数</td>
                <td  style={{
                color: '#2e2e2f'
              }}>邀请奖励</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td  style={{
                color: '#2e2e2f'
              }}>
                 {Ardrop.invitedNumOf}
                </td>
                <td  style={{
                color: '#2e2e2f'
              }}>
                {Ardrop.upInvitedNumOf}
                </td>
                <td  style={{
                color: '#2e2e2f'
              }}>{Ardrop.releasedTo} FTC</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      <div className="pools_detail_index">
        <div className="pools-i">
          <div className="title">
            {/* 我的奖励 */}
            <FormattedMessage id='wdjl' />
          </div>
          <div className="number">{Ardrop.releasedTo} JKC</div>
        </div>
        <div className="pools-i">
          <div className="title">
            {/* 直接邀请 */}
            <FormattedMessage id='zjyq' />
          </div>
          <div className="number">{Ardrop.invitedNumOf}</div>
        </div>
        {/* <div className="pools-i">
          <div className="title">间接邀请</div>
          <div className="number">{Ardrop.upInvitedNumOf}</div>
        </div> */}
      </div>
      {/* 地址二维码 */}
      {
        
        locale == 'en' ? 
        <div className="raise-address-2">
          <img src={zcdzs } />
        </div>
        : 
        <div className="raise-address-1">
        <img src= {zcdz} />
      </div> 
      }
      {/* <div className="raise-address">
        <img src={locale == 'cn' ? zcdz : zcdzs } />
      </div> */}
      <div className="pools_banner">
        <p className="pools_banner_dec">
        {/* message.success('复制成功~') */}
          <CopyToClipboard text={isPool.address}
            onCopy={() => 
              message.success(
                intl.formatMessage({
                   id: 'cvcg',
                })
              )
            }>
            <img className="cv" src={cv} />
          </CopyToClipboard>
          {/* <div onCopy={() => message.success('复制成功~')} className="cv"><img src={cv} /></div> */}
          <div className="xx"></div>
          <span>{isPool.address}</span>
        </p>
        {/* <p className="pools_banner_withdraw_tip" style={{
            fontSize: '14px'
          }}>
          邀请空投规则为:若双方建立了直接邀请关系，邀请人和被邀请人都将得到2枚FTC空投，若双方建立了间接邀请关系，邀请人得到1枚FTC空投奖励，也就是邀请别人和接受别人邀请都能得到奖励。
示例:假设A邀请了B，那么A和B各得2枚FTC空投奖励，B邀请了C，B和C各得2枚FTC空投奖励，同时A和C建立了间接邀请关系，A得到1枚FTC空投奖励，那么A将得到3枚FTC空投奖励，B得到4枚FTC奖励，C得到2枚FTC奖励。
          <br />
          <br />
          <span style={{
            cursor: 'pointer',
            color: '#1890ff'
          }} onClick={shareHaibao}>我的二维码</span>
          </p> */}
      </div>
      {/* <div className="raise-address" onClick={shareHaibao}>
        <img style={{ marginTop: '35px', }} src={dzewm} />
      </div> */}

      {
        
        locale == 'en' ? 
        <div className="raise-address-2" onClick={shareHaibao}>
          <img style={{ marginTop: '35px',width: '140px' }} src={dzewms } />
        </div>
        : 
        <div className="raise-address-1" onClick={shareHaibao}>
        <img style={{ marginTop: '35px', }} src= {dzewm} />
      </div> 
      }

      <div className="ewms" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}><QRCode locale={ locale } id='qrid' value={localAdd + '?address=' + address}  size={160} /> </div>
      {/* 卡片介绍 */}
      {/* <div className="pools-type_content">
      
      <div className="pools-type_card">
          <div className="pools-type_card_box pools-type_private">
            <div className="pools-type_title">
              <div className="pools-type_card_title">
                <div className="pools-type_card_title_right">
                </div>
              </div>
              </div>
              <div className="pools-type_title">
                <div className="pools-type_card_ratio">空投说明<i></i></div>
                <div className="pools-type_card_ratio" style={{textAlign: 'right'}}></div>
              </div>
              <div className="pools-type_title">
                <div className="pools-type_card_ratio" style={{marginTop: "24px"}}></div>
              </div>
                <div className="pools-type_title">
                    <div className="pools-type_card_ratio pools-type_card_access" style={{color: '#2e2e2f'}}>
                    FTC总量为10亿枚，为了感谢广大社区团队的支持，拿出1000万枚参与社区空投激励，空投时间为期一个月，先到先得，投完为止。

                    <br /><br />
                    FTC将于2021年4月29日零时结束空投
                    </div>
                    <div className="pools-type_card_ratio pools-type_card_access" style={{textAlign: 'right'}}>
                    </div>
                </div>
            </div>
            <div className="pools-type_card_box pools-type_private">
            <div className="pools-type_title">
              <div className="pools-type_card_title">
                <div className="pools-type_card_title_right">
                </div>
              </div>
              </div>
              <div className="pools-type_title">
                <div className="pools-type_card_ratio">众筹规则<i></i></div>
                <div className="pools-type_card_ratio" style={{textAlign: 'right'}}></div>
              </div>
              <div className="pools-type_title">
                <div className="pools-type_card_ratio" style={{marginTop: "24px"}}></div>
              </div>
                <div className="pools-type_title">
                    <div className="pools-type_card_ratio pools-type_card_access" style={{color: '#2e2e2f'}}>
                    众筹总量：1亿FTC
                    <br />
                    众筹开始时间：2021-03-28
                    <br /><br />
                    众筹分为三轮进行：
                    第一轮总量为5000万枚FTC：0.15USDT=1FTC；
                    第二轮总量为3000万枚FTC：
                    0.20USDT=1FTC；
                    第三轮总量为2000万枚FTC：
                    0.25USDT=1FTC;
                    <br /><br />
                    每一轮结束后，即开始进行下一轮众筹
                    </div>
                    <div className="pools-type_card_ratio pools-type_card_access" style={{textAlign: 'right'}}>
                    </div>
                </div>
            </div>
            <div className="pools-type_card_box pools-type_private">
            <div className="pools-type_title">
              <div className="pools-type_card_title">
                <div className="pools-type_card_title_right">
                </div>
              </div>
              </div>
              <div className="pools-type_title">
                <div className="pools-type_card_ratio">众筹方式<i></i></div>
                <div className="pools-type_card_ratio" style={{textAlign: 'right'}}></div>
              </div>
              <div className="pools-type_title">
                <div className="pools-type_card_ratio" style={{marginTop: "24px"}}></div>
              </div>
                <div className="pools-type_title">
                    <div className="pools-type_card_ratio pools-type_card_access" style={{color: '#2e2e2f'}}>
                    连接钱包后，直接输入USDT数量，支付成功后，即可得到对应数量的FTC
                    <br /><br />
                    本次众筹为HECO链的众筹，钱包需连接火币主网，或使用支持HECO的钱包
                    <br /><br />
                    每人单次众筹的最大额度为100000USDT
                    </div>
                    <div className="pools-type_card_ratio pools-type_card_access" style={{textAlign: 'right'}}>
                    </div>
                </div>
            </div>
        </div>
      </div> */}
      <div className="gross">
        <span>
          {/* 空投总量为280万，只需支付少量手续费即可实时领取 */}
          <FormattedMessage id='zls' />
        </span>
        <br />
        <div style={{
          marginTop: '20px',
        }}>
          {/* 邀请规则 */}
          <FormattedMessage id='yqgz' />
        </div>
        <span>
          {/* 直接邀请：邀请人得2枚JKC，受邀人得2枚JKC；如果受邀人为间接受邀人，则受邀人得3枚JKC，直接邀请人得2枚JKC，间接邀请人得1枚JKC。 */}
          {/* 邀请得空投；建立邀请关系，邀请人将获得1.5枚JKC，受邀人的6.5枚JKC */}
          <FormattedMessage id='yqjkc' />
        </span>
      </div>
      {/* 底部 */}
      <div className="pools-type_content">
        <div className="pools-type_card">
          <div className="pools-type_card_box pools-type_private">
            <div className="pools-type_title">
              <div className="pools-type_card_title">
                <div className="pools-type_card_title_right">
                </div>
              </div>
            </div>
            <div className="pools-type_title">
              {/* <div className="pools-type_card_ratio"><img src={ktsm} /><i></i></div> */}
              {
                locale == 'en' ? 
                <div className="pools-type_card_ratio">
                  <img style={{width: '200px'}} src={ktsms} /><i></i>
                </div>
                :
                <div className="pools-type_card_ratio">
                <img src={ktsm} /><i></i>
              </div> 
              }
              <div className="pools-type_card_ratio" style={{ textAlign: 'right' }}></div>
            </div>
            {/* <div className="pools-type_title">
              <div className="pools-type_card_ratio" style={{ marginTop: "24px" }}></div>
            </div> */}
            <div className="pools-type_title">
              <div className="pools-type_card_ratio pools-type_card_access" style={{ color: '#0a73eb', fontWeight: "normal" }}>
              {/* JKC总量为8亿枚，本次JKC空投280万枚，为了感谢JKC团队对我们对技术支持，我们将100万枚JKC赠与JKC官方，4.5亿JKC进行线上认筹。 */}
              <FormattedMessage id='xsrzs' />
                  <br /><br />
                  <div style={{ textAlign: 'center' }}>
                    {/* JKC将于2021年7月18日结束空投！！！ */}
                    <FormattedMessage id='jskt' />！！！
                  </div>
                  </div>
              <div className="pools-type_card_ratio pools-type_card_access" style={{ textAlign: 'right' }}>
              </div>
            </div>
          </div>
          <div className="pools-type_card_box pools-type_private">
            <div className="pools-type_title">
              <div className="pools-type_card_title">
                <div className="pools-type_card_title_right">
                </div>
              </div>
            </div>
            <div className="pools-type_title">
              {/* <div className="pools-type_card_ratio" style={{
                textAlign: 'center',
                width: '100%',
                fontSize: '26px'
              }}>空投活动<i></i></div>
              <div className="pools-type_card_ratio" style={{ textAlign: 'right' }}></div> */}
              {/* <div className="pools-type_card_ratio"><img src={index5} /><i></i></div> */}
              {
                locale == 'en' ? 
                <div className="pools-type_card_ratio">
                  <img style={{width: '200px'}} src={index55} /><i></i>
                </div>
                : 
                 <div className="pools-type_card_ratio">
                 <img src={index5} /><i></i>
               </div> 
              }
            </div>
            {/* <div className="pools-type_title">
              <div className="pools-type_card_ratio" style={{ marginTop: "24px" }}></div>
            </div> */}
            <div className="pools-type_title">
              <div className="pools-type_card_ratio pools-type_card_access" style={{
                color: 'rgb(10, 115, 235)', width: '100%',
                display: 'block'
              }}
              >
                
                <div style={{
                  fontSize: '14px',
                  color: 'rgb(10, 115, 235)',
                  fontWeight: 'normal'
                }}>
                  {/* 众筹总量：4.5亿JKC  */}
                  <FormattedMessage id='yi' />
                  <br/>
                  {/* 众筹开始时间：2021-06-15   */}
                  <FormattedMessage id='kaissj' />：2021-06-12 16:00
                  <br/>
                  {/* 众筹分为三轮进：   */}
                  <FormattedMessage id='sanlunj' />：
                  <br/>
                  {/* 第一轮：总量为250000000枚JKC;1JKC=0.008USDT；众筹持续时间15天 */}
                  <FormattedMessage id='dyl1' />
                  <br/>
                  {/* 第二轮：总量为150000000枚JKC;1JKC=0.015USDT；众筹持续时间21天 */}
                  <FormattedMessage id='dyl2' />
                  <br/>
                  {/* 第三轮：总量为50000000枚JKC;1JKC=0.03USDT。 */}
                  <FormattedMessage id='dyl3' />
                </div>
                {/* <br /> */}
                  <span style={{
                  fontSize: '14px',
                  color: 'rgb(10, 115, 235)',
                  fontWeight: 'normal',
                  marginTop: '10px'
                }}>
                  {/* 每一轮时间终止或者众筹目标达成后,即开始进行下一轮众筹。 */}
                  <FormattedMessage id='xylindex' />。
                </span>
                  {/* {Ardrop.time ? Ardrop.time : '2021-04-29 00:00:00' }   */}
              </div>
              <div className="pools-type_card_ratio pools-type_card_access" style={{ textAlign: 'right' }}>
              </div>
            </div>
          </div>
          <div className="pools-type_card_box pools-type_private">
            <div className="pools-type_title">
              <div className="pools-type_card_title">
                <div className="pools-type_card_title_right">
                </div>
              </div>
            </div>
            <div className="pools-type_title">
              {/* <div className="pools-type_card_ratio"><img src={index6} /><i></i></div> */}
              {
                locale == 'en' ? 
                <div className="pools-type_card_ratio">
                  <img style={{width: '200px'}} src={index66} /><i></i>
                </div>
                : 
                <div className="pools-type_card_ratio">
                <img src={index6} /><i></i>
              </div>
              }
              <div className="pools-type_card_ratio" style={{ textAlign: 'right' }}></div>
            </div>
            {/* <div className="pools-type_title">
              <div className="pools-type_card_ratio" style={{ marginTop: "24px" }}></div>
            </div> */}
            <div className="pools-type_title">
              <div className="pools-type_card_ratio pools-type_card_access" style={{ color: '#0a73eb', fontWeight: "normal" }}>
                  {/* 链接钱包后，直接输入USDT数量，支付成功后，即可得到对应数量的JKC。 */}
                  <FormattedMessage id='ljqb' />
                  <br />
                  <p style={{ marginTop: '16px', }}>
                    {/* 本次众筹为HECO链的众筹，钱包需链接火币主网，或使用支持HECO的钱包。 */}
                    <FormattedMessage id='huobiw' />
                  </p>
                  <br />
                  <p style={{ marginTop: '-20px', }}>
                    {/* 每人单次众筹的最大额度为20000 USDT，可进行多次兑换。 */}
                    <FormattedMessage id='dldh' />
                  </p>
                  </div>
              <div className="pools-type_card_ratio pools-type_card_access" style={{ textAlign: 'right' }}>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="xian-1"></div>
      <div className="xian-2"></div> */}
      <div className="jkc">
        <div className="jckindex">
          <div className="what">
            {/* 什么是JKC？ */}
            <FormattedMessage id='sms' />？
          </div>
          <div className="dw"><img src={LogoText} /></div>
        </div>
        <div className="solve">
          {/* JKC为客户提供数字货币领域全面、安全、便捷和专业的资产管理与增值服务，促进数字货币投资领域的发展。 */}
          <FormattedMessage id='jkcfz' />
        </div>
        <div className="draps-activity">
          <div className="idsx" style={{fontSize: '50px', textAlign: 'center', color: '#ffffff'}}><FormattedMessage id='kthddd' /></div>
          <div className="count-down">
            {/* 结束倒计时 */}
            <FormattedMessage id='jsdjs' />
          </div>
          {
            locale == 'en' ?
            <div className="counter" style={{ marginTop: '30px' }}>
              {cutTime !== 0 ? cutTime: '已结束'}
            </div>
            :
            <div className="counter" style={{ marginTop: '30px' }}>
              {cutTime !== 0 ? cutTime: '已结束'}
            </div>
          }
        </div>
        <div className="x-1"></div>
        <div className="x-2"></div>
        <div className="token">
          {/* 令牌 */}
          <FormattedMessage id='lps' />
        </div>
        <div className="jckindex" style={{ marginTop: '17px', }}>
          <div className="what">
            {/* 如何获得JKC */}
            <FormattedMessage id='rhhd' />
          </div>
        </div>
        <div className="contract">
          <span>JKC <FormattedMessage id='jkchydz' />：</span>
          <span>{isPool.underlying.address}</span>
          <span style={{
            marginTop: '30px'
          }}><FormattedMessage id='zcdzs' />：</span>
          <span>{isPool.address}</span>
          <span style={{
            marginTop: '40px',
            color: '#7896D8',
            paddingBottom: '30px'
          }}>
            {/* JKC 总量为8亿枚，本次JKC空投280万枚；为了感谢JKC团队对我们的技术支持，我们将100万枚JKC赠与JKC官方，4.5亿JKC进行线上认筹。后期流动性挖矿3.472亿。 */}
            <FormattedMessage id='hhda' />
          </span>
        </div>
      </div>
      {/* 链接 */}
      <ProductANDClickDownload changeModelShow={changeModelShow} show={show} link={localAdd + '?address=' + address} />
      {/* title="领取空投" */}
      <Modal
        title={intl.formatMessage({
           id: 'zzzzz',
        })}
        centered
        closable={false}
        visible={visible}
        footer={
          [] // 设置footer为空，去掉 取消 确定默认按钮
        }
      >
        <p>
          {/* 检测到你有空投未领取 请领取 */}
          <FormattedMessage id='jcd' />
        </p>
        <Spin spinning={loading} delay={500}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-around'
          }}>

            <a className="pools_detail_btn pools_detail_btn"
              style={{ textAlign: 'center' }}
              onClick={handCanel}
            ><FormattedMessage id='qx' /></a>
            <a className="pools_detail_btn pools_detail_btn_active"
              style={{
                textAlign: 'center', color: '#fff',
                background: '#1890ff'
              }}
              onClick={handleOk}
            ><FormattedMessage id='lq' /></a>
          </div>
        </Spin>
      </Modal>
    </div>
  )
}

export default Ardrop