import React from 'react'
import { FormattedMessage } from 'react-intl'

function tab2 () {
  return (
    <div className="pools_detail_table_box">
      <table className="pools_detail_table" style={{
        border: '1px solid #ebe9e3'
      }}>
        <thead>
          <tr>
            <th><FormattedMessage id='xx10' /></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p><span style={{
                textAlign: 'left',
                lineHeight: '24px',
                margin: '10px 0'
              }}>
              {/* JKC 是由一群数字资产研究者和区块链极客创建而成的一个专注区块链
资产交易的去中心化平台，将面向全球用户提供安全、透明、稳定、高效的区块
链资产交易服务。安全、去中心化、稳定、流动性是 JKC 的核心价值，结合
落地生态为每一位投资者提供高效的投资环境和服务，同时投资者不必担心个人
隐私保护或数字资产的安全性，平台专业的金融单元将为投资者提供丰富风险对
冲工具和投资选择 */}
{/* XEC的创始团队来自于几个欧洲大型足球俱乐部的核心粉丝团队的领导者，
并且和西班牙巴塞罗那足球俱乐部、
巴黎圣日耳曼足够俱乐部和尤文图斯足球俱乐部等几个欧洲顶尖的足够俱乐部建立了广泛以及多方位的合作关系，
XEC是一个多链的去中心化平台。当前基于Heco链进行发行，
后续会陆续上线ETH、BSC、Polygon（matic）、AVAX、Solana等去中心化智能合约平台，
XEC的核心产品为XEC粉丝代币发行平台、 XEC Swap、XEC NFT等产品，打造全生态的去中心化体育资产平台。 */}
<FormattedMessage id='p32' />
                </span></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default tab2