
export const getLeftTime = (time)=>{
    if (time) {

        const time1 = time * 1000;
        const date = new Date(time1);
        const now = new Date();
        const lefttime = time;
        if (lefttime < 0) return 0 
        let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
        let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
        let leftm = Math.floor(lefttime / (1000 * 60) % 60);
        let lefts = Math.floor(lefttime / 1000 % 60);
        const left = {
            days: leftd < 0 ? 0 : leftd,
            hours: lefth < 0 ? 0 : lefth,
            minutes: leftm < 0 ? 0 : leftm,
            seconds: lefts < 0 ? 0 : lefts,
        };
        return left
    } else {
        return null
    }
}
export const getYMDHMS = (timestamp) => {
    let time = new Date(timestamp*1000)
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let date = time.getDate()
    let hours = time.getHours()
    let minute = time.getMinutes()
    let second = time.getSeconds()

    if (month < 10) { month = '0' + month }
    if (date < 10) { date = '0' + date }
    if (hours < 10) { hours = '0' + hours }
    if (minute < 10) { minute = '0' + minute }
    if (second < 10) { second = '0' + second }
    return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
  }
//   倒计时
  export function timers(timestamp,lang) {
        //获取现在的时间
        var now = new Date();
        //获取你想要的未来时间
        let future = new Date(timestamp*1000)
        // var future = new Date(StartTime);
        var time = future.getTime() - now.getTime();
        //获取距离的天数
        var day = parseInt(time / 24 / 60 / 60 / 1000);
        //获取距离的小时
        var hour = parseInt(time / 1000 / 60 / 60 % 24);
        //获取分
        var minute = parseInt(time / 1000 / 60 % 60);
        //获取秒
        var sec = parseInt(time / 1000 % 60);
    
        if(time < 0) {
            console.log( `距离开始时间为还有00天00小时00分00秒`)
            return 0
        }
        var str = ' '
        if (day > 0) {
            lang ?
            str += day.toString().padStart(2,0) + 'D' 
            : 
            str += day.toString().padStart(2,0) + '天'
        }
        if (hour > 0) {
            lang ? 
            str += hour.toString().padStart(2,0) + 'H'
            :
            str += hour.toString().padStart(2,0) + '时 '
        }
        if (minute > 0) {
            lang ? 
            str += minute.toString().padStart(2,0) + 'M'
            :
            str += minute.toString().padStart(2,0) + '分 '
        }
        // console.log('getYMDHMS', getYMDHMS(timestamp))
        if (lang) {
            return `${str} ${sec.toString().padStart(2,0)} S`
        } else {
            return `${str} ${sec.toString().padStart(2,0)} 秒`
        }
    // }
        
    }
export const getdelineTime = (time)=>{
    if (time) {
        const time1 = time * 1000;
        const lefttime = time1
        if (lefttime < 0) return 0 
        let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
        let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
        let leftm = Math.floor(lefttime / (1000 * 60) % 60);
        let lefts = Math.floor(lefttime / 1000 % 60);
        const left = {
            days: leftd < 0 ? 0 : leftd,
            hours: lefth < 0 ? 0 : lefth,
            minutes: leftm < 0 ? 0 : leftm,
            seconds: lefts < 0 ? 0 : lefts,
        };
        return left
    } else {
        return null
    }
}

export const getPoolLeftTime = (time)=>{
    if (time) {

        const time1 = time * 1000;
        const date = new Date(time1);
        const now = new Date();
        const lefttime = (date - now);
        let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24));
        let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24);
        let leftm = Math.floor(lefttime / (1000 * 60) % 60);
        let lefts = Math.floor(lefttime / 1000 % 60);
        const left = {
            days: leftd < 0 ? 0 : leftd,
            hours: lefth < 0 ? 0 : lefth,
            minutes: leftm < 0 ? 0 : leftm,
            seconds: lefts < 0 ? 0 : lefts,
        };
        return left
    } else {
        return null
    }
}


export const getPercent = (time) =>{

    if(0 <= time && time <= 24){
        return 50
    }else if(24 < time && time <= 48){
        return 40
    }else if(48 < time && time <= 72){
        return 30
    }else if(72 < time && time <= 96){
        return 20
    }else if(96 < time && time <= 120){
        return 15
    }else if(120 < time && time <= 144){
        return 10
    }else if(144 < time && time <= 168){
        return 8
    }else if(168 < time && time <= 192){
        return 6
    }else if(192 < time && time <= 216){
        return 4
    }else if(216 < time && time <= 240){
        return 2
    }else {
        return 0
    }
}


export const getTime = (day, hour, min) => {
    let time = 0;
    if ( day ) {
        time += 24 * 60 * 60 * day;
    }
    if ( hour ) {
        time += 60* 60 * hour;
    }
    if ( min ) {
        time += 60 * min;
    }
    return time;
}
