import './App.css';
import 'antd/dist/antd.css';
import './assets/css/style.scss'
import { ContextProvider } from './reducer'
import Intl from './locale/intl'

import { HashRouter as Router, Switch, Route } from 'react-router-dom'
// import Home from './views/home/index'
import Pool from './views/pool/index'
import Ardrop from './views/ardrop/index'

function App() {
  return (
    <ContextProvider>
      <Intl>
        <Router>
         
          <Switch>
            {/* <Route
              exact
              path='/'
              component={Home}
              ></Route> */}
            <Route
              exact
              path='/'
              component={Pool}
            ></Route>
            <Route
              exact
              path='/ardrop'
              component={Ardrop}
            ></Route>
          </Switch>
        </Router>
      </Intl>
    </ContextProvider>
  );
}

export default App;
