import React, { useContext, useState, useEffect } from 'react'
import detectEthereumProvider from '@metamask/detect-provider'
import ERC20 from '../../web3/abi/ERC20.json'
import Starter from '../../web3/abi/Starter.json'
import Web3 from 'web3'
import { formatAmount, numToWei, splitFormat } from '../../utils/format'
import { mainContext } from '../../reducer'
import pools from './pools'
import { message } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

const pool = pools[0]

function PoolDialog(props) {
  const intl = useIntl()
  const {show, changeDialogShow, changeMessageDialog, changeText} = props
  const { dispatch, state } = useContext(mainContext)
  const {  poolContract } = state
  const { address } = state
  const [approve, setApprove] = useState(true)
  const [amount, setAmount] = useState('') 
  const [balance, setBalance] = useState('0') 

  useEffect( async () => {
    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    const currency_token = new web3.eth.Contract(ERC20.abi, pool.currency.address)
    let allowValue = 0
    let allow = '' 
    if (address) {
      allow =  currency_token.methods.allowance(address, pool.address).call()
      allowValue = await allow
      if (allowValue > 0) {
        setApprove(false)
        console.log('是否授权', allowValue)
      }
    }
    
  })
  useEffect( async () => {
    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    const currency_token = new web3.eth.Contract(ERC20.abi, pool.currency.address)
    let wall = ''
    let wallValue = ''
    if (address) {
      wall =  currency_token.methods.balanceOf(address).call()
      wallValue = await wall

      setBalance(formatAmount(wallValue, pool.currency.decimal))
      console.log('balance' , wallValue)
    }
    
  })

  const onChange = (e) => {
    const { value } = e.target;
    const re = /^[0-9]+([.|,][0-9]+)?$/g;
    if (
        value === '' ||
        re.test(value) ||
        (value.split('.').length === 2 &&
            value.slice(value.length - 1) === '.')
    ) {
      setAmount(value);
    }
  };

  const onApprove = async (e) => {
    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    const contract = new web3.eth.Contract(ERC20.abi, pool.currency.address)
    changeDialogShow() // close router.currentRoute
    contract.methods
      .approve(
        pool.address,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      )
      .send({
        from: address,
      })
      .on('transactionHash', (hash) => {
       console.log('等待中....')
        changeText({
          status: 0,
          text: 'Pending...'
        })
        changeMessageDialog(true)
      })
      .on('receipt', (_, receipt) => {
        console.log('approve success')
        changeText({
          status: 1,
          text: 'Congratulations!'
        })
        changeMessageDialog(true)
        setAmount('')
      })
      .on('error', (err, receipt) => {
        console.log('approve error', err)
        changeText({
          status: -1,
          text: 'Transaction failed, please try again'
        })
        changeMessageDialog(true)
        setAmount('')
      })
  }
  const onConfirm = async (e) => {
    let minAmount = formatAmount(poolContract.minAmount, pool.currency.decimal)
    if (minAmount > amount) {
      message.warning('最小为' + minAmount + '个')
      return
    }
    const provider = await detectEthereumProvider()
    const web3 = new Web3(provider)
    const pool_contract = new web3.eth.Contract(Starter, pool.address)
    const _amount = numToWei(amount, pool.currency.decimal)
    changeDialogShow() // close router.currentRoute
    // debugger
    pool_contract.methods
      .purchase(_amount)
      .send({
        from: address,
      })
      .on('transactionHash', (hash) => {
        console.log('支付等待....')
        changeText({
          status: 0,
          text: 'Pending...'
        })
        changeMessageDialog(true)
        setAmount('')
      })
      .on('receipt', (_, receipt) => {
        console.log('BOT staking success')
        changeText({
          status: 1,
          text: 'Congratulations!'
        })
        changeMessageDialog(true)
        setAmount('')
      })
      .on('error', (err, receipt) => {
        console.log('BOT staking error', err)
        changeText({
          status: -1,
          text: 'Transaction failed, please try again'
        })
        changeMessageDialog(true)
        setAmount('')
      })
  }
  const confirm = (e) => {
    console.log('amount', amount)
  }

  return (
  <div className="modal-show" style={ {display: show ? 'block' : 'none'} }>
  <div className="wrapper" style={{zIndex: 10}}>
    <div className="modal">
      <div className="modal__box">
        <form className="form-app" action="/">
          <div className="form-app__inner deposit">
            <div className="form-app__title h3" style={{marginTop: '0px', marginBottom: '10px'}}>
              {/* 加入JKC兑换池 */}
              <FormattedMessage id='jrsz' />
            </div>
            <div className="form-app__tip">1USDT=
            { poolContract && poolContract.status ? ' ' + (1 / formatAmount(poolContract.status.rate_, 18) ).toFixed(6) : ''} XEC
            </div>
            <div className="form-app__inputbox-after-text"
              style={{marginBottom: '0px', color: 'rgb(34, 41, 47)', textAlign: 'left', opacity: 1}}><FormattedMessage id='yex' />: {balance} USDT<br /></div>
            <div className="deposit__inputbox form-app__inputbox">
              {
                approve
                ?
                <span style={{color: '#000000'}}><FormattedMessage id='p30' /></span>
                :
                <div className="form-app__inputbox-control">
                  <div className="form-app__inputbox-input"><input 
                    value={ amount }
                    onChange={ onChange }
                  className="input" placeholder={ 
                    poolContract && poolContract.minAmount ?   intl.formatMessage({
                       id: 'sge',
                    }) + formatAmount(poolContract.minAmount, pool.currency.decimal) +  intl.formatMessage({
                       id: 'ggg',
                    })
                    : ''
                  } />
                  </div>
                  <div className="form-app__inputbox-up">
                    {/* <div className="form-app__inputbox-up-pref" >Max</div> */}
                  </div>
                </div>
              }
            </div>
            <div className="form-app__submit form-app__submit--row">
              <button 
                onClick={changeDialogShow}
              className="btn btn--outline btn--medium" type="button"><FormattedMessage id='qxszc' /></button>
              {
                approve 
                ?
                <button type="button" className="btn btn--medium" onClick={onApprove}><FormattedMessage id='sqxx' /></button>
                :
                <button type="button" className="btn btn--medium" onClick={onConfirm}><FormattedMessage id='qrszjs' /></button>
              }
                
              </div>
          </div>
        </form>
      </div>
    </div>
    </div>
    </div>
  )
}

export default PoolDialog
