const en_US = {
  jrsz: 'Join JKC Bonus Pool',
  qxszc: 'cancel',
  qrszjs: 'affirm',
  sqxx: 'authorization',
  yex: 'balance',
  sge: 'The minimum subscription',
  ggg: 'USDT',
  kthddd: 'Drop activity',
  linkWallet: 'LinkWallet',
  cbs: 'copylink',
  zzzzz: 'Get the drop',
  aaa: 'Please connect your wallet first!',
  kt: 'air-drop',
  fhsy: 'back',
  lp: 'This is a smart contract address, please do not transfer any tokens in it',
  dy: 'First round of exchange',
  de: 'Second round exchange',
  ds: 'Third round exchange',
  jxz: 'underway',
  dhjd: 'For the progress',
  dyljzdjs: 'The countdown to the first round',
  derjzdjs: 'The second round of the deadline countdown',
  dsljzdjs: 'The countdown to the third round',
  zheclck: 'Check out BSC',
  dslydhs: 'The third round has been exchanged',
  ywc: 'off the stocks',
  delydh: 'The second round has been exchanged',
  dylydh: 'The first round has been exchanged',
  ckjl: 'Fundraising record',
  ljtz: 'cumulative investment',
  dblj: 'Tokens accumulated',
  zccxq: 'Details of the crowdfunding pool',
  xmjj: 'project brief',
  ljqbfhfx: 'Please connect the wallet first before you can share',
  lqcg: 'Get the success',
  lqsb: 'Get the failure',
  jkcxxxx: 'Founded by a group of digital asset researchers and blockchain geeks, it is a decentralized platform focusing on blockchain asset trading, which will provide secure, transparent, stable and efficient blockchain asset trading services for global users',
  xlpl: 'Security, decentralization, stability and liquidity are the core values of JKC, combining ecological landing for each investor to provide efficient investment environment and service, at the same time, investors dont have to worry about personal privacy or security of digital assets, platform of professional financial unit will provide investors with rich risk hedging tools and investment options',
  wdjl: 'My reward',
  zjyq: 'DirectInvitation',
  cvcg: 'Copy success',
  zls: 'A total of 2.8 million airdrops will be available in real time for a small fee',
  yqgz: 'Invite rules',
  yqjkc: 'Invited airdrop;To establish an invitation relationship, the invitee will get 1.5 JKCs and the invitee 6.5 JKCs',
  xsrzs: 'There are 800 million JKC pieces in total, and 2.8 million JKC pieces will be airdropped this time. In order to thank the JKC team for their technical support to us, we will donate 1 million JKC pieces to the JKC authorities and 450 million JKC pieces for online recognition。',
  jskt: 'JKC will end airdrops at 2021-06-21 24:00',
  zczl: 'The raised amount',
  yi: 'Total Crowdfunding: 450 million JKC',
  kaissj: 'Start time for crowdfunding',
  sanlunj: 'Crowdfunding is divided into three rounds',
  dyl1: 'Round 1: June 12th 16:00 to June 27th 24:00;For a total of 15 days;1 usdt JKC = 0.008;Total 250 million;',
  dyl2: 'Round 2:0:00 on June 28th to 24:00 on July 7th;For a total of 10 days;1 usdt JKC = 0.015;A total of 150 million;',
  dyl3: 'Round 3:0:00 on July 8th to 24:00 on July 17th;For a total of 10 days;1 usdt JKC = 0.3;A total of 50 million;2.8 million JKC airdrops;',
  xylindex: 'The next round of crowdfunding will start after each round of time ends or the crowdfunding goal is achieved',
  ljqb: 'After the link wallet, directly input the USDT quantity, after the successful payment, you can get the corresponding amount of JKC',
  huobiw: 'This crowd-funding is the crowd-funding of the BSC chain. The wallet should be linked to the Huobi mainnet or a wallet supporting BSC',
  dldh: 'The maximum amount of single crowdfunding per person is 20,000 USDT, which can be exchanged for several times',
  sms: 'What is JKC',
  jkcfz: 'JKC provides customers with comprehensive, secure, convenient and professional asset management and value-added services in the digital currency field, and promotes the development of the digital currency investment field',
  jsdjs: 'End countdown',
  yjss: 'finished',
  lps: 'token',
  rhhd: 'How do I get JKC',
  jkchydz: 'Contract address',
  zcdzs: 'The raise address',
  hhda: 'The total number of JKC is 800 million, of which 2.8 million were dropped by air.In order to thank the JKC team for their technical support to us, we will donate 1 million JKC pieces to the JKC official, and 450 million JKC pieces for online subscription.Later fluidity mining 347.2 million pieces',
  jcd: 'You have an airdrop detected. Please pick it up',
  qx: 'cancel',
  lq: 'get',
  kssj: 'start time',
  yyqhs: 'language switch',
  xx1: 'Crowdfunding pool information',
  xx2: 'The raise to',
  xx3: 'The minimum limit',
  xx4: 'not have',
  xx5: 'The biggest limit',
  xx6: 'The token information',
  xx7: 'name',
  xx8: 'Contract address',
  xx9: 'gross',
  xx10: 'item information',
  xx11: 'JKC is a decentralized platform focused on blockchain asset trading created by a group of digital asset researchers and blockchain geeks. It will provide secure, transparent, stable and efficient blockchain asset trading services for global users.Security, decentralization, stability and liquidity are the core values of JKC, combining ecological landing for each investor to provide efficient investment environment and service, at the same time, investors dont have to worry about personal privacy or security of digital assets, platform of professional financial unit will provide investors with rich risk hedging tools and investment options',
  p1: 'in regard to',
  p2: 'Attend the IDO',
  p3: 'Check out BSC',
  p4: 'in regard to',
  p5: 'About the XEC:The full name of XEC is Europe Championship Coin, and the Chinese name is European Championship Coin, where X stands for the project that can link the whole industrial chain of digital assets and sports industry, and stands for infinite possibilities. XEC, referred to as XEC, was founded in Barcelona, Spain, the European Sports Capital in 2021.XEC is a digital token applied to sports competition, IP digitalization of sports stars, NFT, e-sports and other fields. It is committed to turning IP NFT of sports stars into NFT and helping sports teams and well-known organizations in the sports industry incubate and create fan tokens and NFT assets.We will build sports assets integrated with the whole industrial chain and NFT decentralized publishing, trading and circulation platforms, and create a blockchain ecosystem of the whole industrial chain of sports, athletics and entertainment.',
  p6: 'trunk',
  p7: 'Indirect invitation',
  p8: 'crowdfunding',
  p9: 'site',
  p10: 'My QR code',
  p11: 'rule',
  p12: 'Total Crowdfunding: 10 million',
  p13: 'Total amount of crowd-funding: 1000. Start time: 2021-07-01',
  p14: 'Crowdfunding is divided into three rounds:',
  p15: 'Round 1:5 million XECs in total; 0.15 USDT XEC = 1',
  p16: 'Round 2:3 million XECs in total; 0.20 USDT XEC = 1',
  p17: 'Round 3:2 million XECs in total; 0.25 USDT XEC = 1',
  p18: 'After each round, the next round of crowdfunding begins.',
  p19: 'way',
  p20: 'After the link wallet, directly enter the USDT quantity, after the successful payment, you can get the corresponding number of XEC.',
  p21: 'This crowd-funding is the crowd-funding of the BSC chain. The wallet should be linked to Huobi mainnet or a wallet that supports BSC.',
  p22: 'The maximum amount of single crowdfunding per person is 100,000 USDT, which can be exchanged for several times.',
  p23: 'What is the XEC',
  p24: 'XEC is a multi-chain decentralized platform, currently distributed based on the BSC chain;The specific application scenarios of XEC tokens are the decentralized governance of XEC platform, as well as the purchase of cooperative sports star merchandise, gifts, tickets for cooperative events, and sports IP NFT assets through XEC.',
  p25: 'token',
  p26: 'How do I get XEC',
  p27: 'Contract address',
  p28: 'XEC issued a total of 1 billion pieces, of which 10 million pieces, namely 1% for IDO subscription, 9% for adding initial liquidity, 60% for community mining, 30% for charitable donations in the field of sports, no private equity and no pre-mining, to create a truly decentralized and community-based sports fan meme token.',
  p29: 'Do you accept invitations from others',
  p30: 'Authorization is required for recognition operations',
  p31: 'Invite friends together, scan code recognition',
  p32: 'XEC founding team comes from the core of several large European football club fans team leader, and the Spanish football club Barcelona, Paris st germain enough and Juventus football club and so on several of Europes top clubs enough established extensive and all-round cooperation, XEC is more than a chain of decentralized platform.Currently, it is launched based on the BSC chain. Decentalized smart contract platforms such as ETH, BSC, Polygon (Matic), Avax, and Solana will be launched in the future. The core products of XEC include XEC fan token issuing platform, XEC Swap, XEC NFT and other products.Build a fully ecological decentralized sports assets platform.'
}
export default en_US
